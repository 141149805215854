import React, { useState } from 'react';
import WithdrawProgressFilter from './WithdrawProgressFilter';
import WithdrawProgressTable from './WithdrawProgressTable';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const WithdrawProgress = () => {
	const [query, setQuery] = useState<TransactionQueryRequest>();
	const [params, setParams] = useState({
		page: 1,
		pageSize: 10,
	});
	return (
		<div>
			<WithdrawProgressFilter
				setQuery={setQuery}
				params={params}
				setParams={setParams}
			/>
			<WithdrawProgressTable
				query={query}
				params={params}
				setParams={setParams}
			/>
		</div>
	);
};

export default WithdrawProgress;
