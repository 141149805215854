import React from 'react';
import styled from 'styled-components';
import { TYPE } from '..';
import TitlePage from '../../UI/TitlePage';
import { ExecuteDetail } from '.';
import { convertStatusToCapitalized } from '../../../utils';
import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto';
const TitleWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const TypeTag = styled('div')<{ type: string }>`
	border: ${(props) =>
		`${props.type === TYPE.BUY ? '1px solid #03a66d' : '1px solid #CF304A'}`};
	border-radius: 5px;
	padding: 5px 20px;
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: ${(props) => `${props.type === TYPE.BUY ? '#03a66d' : '#CF304A'}`};
		margin: 0;
	}
`;
const CryptoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 5px;
`;
const SymbolImg = styled('img')`
	width: 20px;
`;
const SymbolText = styled('p')`
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	color: #ffffff;
	margin: 0;
`;
const FromText = styled('p')`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #1c84e4;
	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #737283;
	}
`;

export default function Title({
	executeDetail,
}: {
	executeDetail: ExecuteDetail;
}) {
	return (
		<TitlePage
			title={
				<TitleWrapper>
					<TypeTag type={executeDetail.type.split('_')[1]}>
						<p>
							{convertStatusToCapitalized(executeDetail.type.split('_')[1])}
						</p>
					</TypeTag>
					<CryptoWrapper>
						<SymbolImg
							src={`/images/tokens/${executeDetail.assetSymbol?.toLowerCase()}.svg`}
							alt="usdt"
						/>
						<SymbolText>{executeDetail.assetSymbol || ''}</SymbolText>
					</CryptoWrapper>
					<div>
						<FromText>
							<span>
								{executeDetail.type === ExecuteOrderType.BUY ? 'from' : 'to'}
							</span>{' '}
							{executeDetail.targetUserInfo?.email}
						</FromText>
					</div>
				</TitleWrapper>
			}
		/>
	);
}
