import { Button, Form } from 'antd';
import { getUnixTime } from 'date-fns';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import {
	GameAccountTransactionStatus,
	GameAccountTransactionType,
	IRefAuditQuery,
} from 'skychain-game-profile-js/lib/dto/ref.query';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/config';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeDate from 'dayjs/plugin/localeData';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import CustomSelect from '../../UI/CustomSelect';
import { convertStatusToCapitalized } from '../../../utils';
import { END_OF_WEEK, TWO_YEARS_AGO } from '../../../constants';

dayjs.extend(weekday);
dayjs.extend(localeDate);

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;
const TxTypes = Object.values(GameAccountTransactionType).map((type) => ({
	label: convertStatusToCapitalized(type),
	value: type,
}));

const Statuses = Object.values(GameAccountTransactionStatus).map((type) => ({
	label: convertStatusToCapitalized(type),
	value: type,
}));

interface Props {
	setQuery: (query: IRefAuditQuery) => void;
	query: IRefAuditQuery;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}

const AuditCommissionFilter: React.FC<Props> = ({
	setQuery,
	query,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const onFinish = (values: any) => {
		const fromTime = values.date
			? getUnixTime(new Date(values.date[0]))
			: TWO_YEARS_AGO;
		const toTime =
			values.date?.length > 1
				? getUnixTime(new Date(values.date[1]))
				: END_OF_WEEK;
		const newQuery = { ...query, fromTime, toTime, ...values };
		delete newQuery.date;
		setParams({ ...params, page: 1 });
		setQuery(newQuery);
		navigate(
			`${ROUTES.FRANCHISE_AUDIT_COMMISION}?${createQueryString(newQuery)}`,
			{
				replace: true,
			}
		);
	};
	const createQueryString = (params: any) => {
		let queryString = '';
		const arr = Object.keys(params).filter(
			(key) => params[key]?.toString().length > 0
		);
		arr.forEach((key, index) => {
			if (params[key]?.toString().length > 0) {
				queryString += `${key}=${params[key]}${
					index < arr.length - 1 ? '&' : ''
				}`;
			}
		});
		return queryString;
	};

	useEffect(() => {
		form.setFieldsValue({
			email: query.email,
			date: [
				dayjs(new Date(query.fromTime * 1000), 'YYYY/MM/DD'),
				dayjs(new Date(query.toTime * 1000), 'YYYY/MM/DD'),
			],
			type: query.type,
			status: query.status,
		});
	}, [query]);

	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: debounce(() => {
								form.submit();
							}, 500),
							allowClear: true,
							placeholder: '',
						}}
					/>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: debounce(() => {
								form.submit();
							}, 500),
						}}
					/>
					<CustomSelect
						label="Status"
						options={Statuses}
						name="status"
						selectProps={{
							size: 'large',
							allowClear: true,
							style: {
								width: 200,
							},
							onChange: () => {
								form.submit();
							},
						}}
					/>
					<CustomSelect
						label="Types"
						options={TxTypes}
						name="type"
						selectProps={{
							size: 'large',
							allowClear: true,
							style: {
								width: 200,
							},
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default AuditCommissionFilter;
