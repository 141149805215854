import React from 'react';
import CustomSelect from '../../UI/CustomSelect';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { TxStatusOptions } from '../../../constants';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';
import { getUnixTime } from 'date-fns';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		gap: 0;
		width: 100%;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 10px;
	flex-wrap: wrap;
`;

interface Props {
	setQuery: (params: TransactionQueryRequest) => void;
	params: any;
	setParams: (params: any) => void;
}
const WithdrawProgressFilter: React.FC<Props> = ({
	setQuery,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);

		filterTimeout = setTimeout(() => {
			setParams({ ...params, page: 1 });
			const fromDate = getUnixTime(values.date && new Date(values.date[0]));
			const toDate = getUnixTime(values.date && new Date(values.date[1]));
			setQuery({ ...values, fromDate, toDate });
		}, 500);
	};
	return (
		<Wrapper>
			<Form onFinish={onFinish} form={form} layout="vertical">
				<Filter>
					<CustomRangePicker
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
						name={'date'}
						label="Time"
					/>
					<CustomSelect
						name={'state'}
						label="Status"
						options={TxStatusOptions}
						selectProps={{
							placeholder: 'Select Tx Status',
							style: { width: 200 },
							allowClear: true,
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default WithdrawProgressFilter;
