import React, { useState } from 'react';
import { ReportingQueryDto } from 'skychain-reporting-js/lib/dto';
import { format } from 'date-fns';
import { PAST_WEEK } from '../../GameManagement/Orders';
import Table from '../Table';
import GameExperienceReportFilter from './Filter';
import { END_OF_WEEK, START_OF_WEEK } from '../../../constants';

const GameExperienceReport = () => {
	const [query, setQuery] = useState<ReportingQueryDto>({
		code: 'game_center_game_experience_register_overview',
		params: [
			'%%',
			format(new Date(START_OF_WEEK * 1000), 'yyyy-MM-dd'),
			format(new Date(END_OF_WEEK * 1000), 'yyyy-MM-dd'),
		],
		page: 1,
		pageSize: 10,
	});

	return (
		<div>
			<GameExperienceReportFilter query={query} setQuery={setQuery} />
			<Table query={query} setQuery={setQuery} />
		</div>
	);
};

export default GameExperienceReport;
