import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import CustomTable from '../../UI/CustomTable';
import { Switch, notification } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	QueryGameVendor,
	Game,
	QueryGame,
	GameStatus,
} from 'skychain-game-center-js/lib/dto';
import { PageOptions } from 'user-identity-js/lib/dto';
import { RootState, useAppDispatch } from '../../../redux/store';
import { format } from 'date-fns';
import UpdateStatusModal from '../../HotWallets/UpdateStatusWalletModal';
import styled from 'styled-components';
import Filter from './Filter';
import { setIsLoading } from '../../../redux/reducers/system.slice';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;
const Games = () => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [query, setQuery] = useState<QueryGame>({
		orders: ['priority asc', 'vendorId asc', 'gameName asc'],
	});
	const [api, contextHolder] = notification.useNotification();
	const [games, setGames] = useState<Game[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedGame, setSelectedGame] = useState<Game>();
	const dispatch = useAppDispatch();
	const columns: ColumnType<Game>[] = [
		{
			title: 'Games',
			dataIndex: 'gameName',
			render: (_, record) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<img
						src={record.image}
						style={{
							width: '70px',
							height: '70px',
							objectFit: 'cover',
							borderRadius: '100%',
						}}
					/>
					<div>{record.gameName}</div>
				</div>
			),
		},
		{
			title: 'Suppliers',
			dataIndex: 'vendorName',
		},
		{
			title: 'Game Catalogue',
			dataIndex: 'category',
		},

		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			render: (text) => (
				<div>{format(new Date(text * 1000), 'yyyy-MM-dd')}</div>
			),
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
		},
		{
			title: 'Active',
			render: (_, record) => (
				<Switch
					checked={record.status === GameStatus.ACTIVED ? true : false}
					onClick={() => {
						setSelectedGame(record);
						setOpenModal(true);
					}}
				/>
			),
		},
	];

	useEffect(() => {
		getGames(gameCenterAdminClient, params, query);
	}, [gameCenterAdminClient, params, query]);

	const updateGame = async (
		gameCenterAdminClient: GameCenterAdminClient,
		id: string,
		data: Game
	) => {
		setOpenModal(false);
		if (!gameCenterAdminClient) return;
		try {
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.updateGame(id, data);
			if (res.id) {
				api.success({
					message: 'Update game success',
				});
				getGames(gameCenterAdminClient, params, query);
			}
		} catch (error) {
			api.error({
				message: 'Update game failed',
			});
		} finally {
			dispatch(setIsLoading(false));
		}
	};
	const getGames = async (
		gameCenterAdminClient: GameCenterAdminClient,
		params?: PageOptions,
		query?: QueryGameVendor
	) => {
		if (!gameCenterAdminClient) return;
		try {
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.getGames(query, params);
			setTotal(res.meta.totalRecord || 0);
			setGames(res.data);
		} catch (error) {
			console.log('getSuppliers', error);
		} finally {
			dispatch(setIsLoading(false));
		}
	};
	const onChangeTable = (pagination: TablePaginationConfig) => {
		window.scrollTo(0, 0);
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};
	return (
		<Wrapper>
			<Filter setQuery={setQuery} params={params} setParams={setParams} />
			<CustomTable
				columns={columns}
				dataSource={games}
				pageSize={params.pageSize}
				total={total}
				onChange={onChangeTable}
				current={params.page}
			/>
			{selectedGame && (
				<UpdateStatusModal
					open={openModal}
					type="game"
					isActive={selectedGame.status === GameStatus.ACTIVED ? true : false}
					onClose={() => setOpenModal(false)}
					onSubmit={() =>
						updateGame(gameCenterAdminClient, selectedGame.id, {
							...selectedGame,
							status:
								selectedGame.status === GameStatus.INACTIVED
									? GameStatus.ACTIVED
									: GameStatus.INACTIVED,
						})
					}
				/>
			)}
			{contextHolder}
		</Wrapper>
	);
};

export default Games;
