import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import CustomTable from '../../UI/CustomTable';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import { PageOptions } from 'user-identity-js/lib/dto';
import { useEffect, useState } from 'react';
import {
	GameStatus,
	GameVendor,
	QueryGameVendor,
} from 'skychain-game-center-js/lib/dto';
import { format } from 'date-fns';
import { Switch, notification } from 'antd';
import UpdateStatusModal from '../../HotWallets/UpdateStatusWalletModal';
import Filter from './Filter';
import styled from 'styled-components';
import { setIsLoading } from '../../../redux/reducers/system.slice';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;
const Suppliers = () => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [query, setQuery] = useState<QueryGameVendor>({
		orders: ['priority asc', 'name asc'],
	});
	const dispatch = useAppDispatch();
	const [api, contextHolder] = notification.useNotification();
	const [suppliers, setSuppliers] = useState<GameVendor[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedSupplier, setSelectedSupplier] = useState<GameVendor>();

	const columns: ColumnType<GameVendor>[] = [
		{
			title: 'Suppliers',
			key: 'suppliers',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
					}}
				>
					<img
						src={record.image}
						style={{
							width: '70px',
							height: ' 70px',
							objectFit: 'contain',
							borderRadius: '5px',
						}}
					/>
					<div>{record.name}</div>
				</div>
			),
		},
		{
			title: 'Code',
			dataIndex: 'vendorCode',
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			render: (text) => (
				<div>{format(new Date(text * 1000), 'yyyy-MM-dd')}</div>
			),
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
		},
		{
			title: 'Active',
			render: (_, record) => (
				<Switch
					checked={record.status === GameStatus.ACTIVED ? true : false}
					onClick={() => {
						setSelectedSupplier(record);
						setOpenModal(true);
					}}
				/>
			),
		},
	];

	useEffect(() => {
		getSuppliers(gameCenterAdminClient, params, query);
	}, [gameCenterAdminClient, params, query]);

	const updateSupplier = async (
		gameCenterAdminClient: GameCenterAdminClient,
		id: string,
		data: GameVendor
	) => {
		setOpenModal(false);
		if (!gameCenterAdminClient || !id || !data) return;
		try {
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.updateGameVendor(id, data);
			if (res.id) {
				api.success({
					message: 'Update supplier success',
				});
				getSuppliers(gameCenterAdminClient, params, query);
			}
		} catch (error) {
			api.error({
				message: 'Update supplier failed',
			});
		} finally {
			dispatch(setIsLoading(false));
		}
	};
	const getSuppliers = async (
		gameCenterAdminClient: GameCenterAdminClient,
		params?: PageOptions,
		query?: QueryGameVendor
	) => {
		if (!gameCenterAdminClient) return;
		try {
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.getGameVendors(query, params);
			setTotal(res.meta.totalRecord || 0);
			setSuppliers(res.data);
		} catch (error) {
			console.log('getSuppliers', error);
		} finally {
			dispatch(setIsLoading(false));
		}
	};
	const onChangeTable = (pagination: TablePaginationConfig) => {
		window.scrollTo(0, 0);
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};

	return (
		<Wrapper>
			<Filter setQuery={setQuery} params={params} setParams={setParams} />
			<CustomTable
				columns={columns}
				dataSource={suppliers}
				pageSize={params.pageSize}
				total={total}
				onChange={onChangeTable}
				current={params.page}
			/>
			{selectedSupplier && (
				<UpdateStatusModal
					open={openModal}
					type="supplier"
					isActive={
						selectedSupplier.status === GameStatus.ACTIVED ? true : false
					}
					onClose={() => setOpenModal(false)}
					onSubmit={() =>
						updateSupplier(gameCenterAdminClient, selectedSupplier?.id, {
							...selectedSupplier,
							status:
								selectedSupplier.status === GameStatus.ACTIVED
									? GameStatus.INACTIVED
									: GameStatus.ACTIVED,
						})
					}
				/>
			)}
			{contextHolder}
		</Wrapper>
	);
};

export default Suppliers;
