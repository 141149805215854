import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserIdentityAdminClient } from 'user-identity-js';
import { getEnv } from '../../utils';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { DecentralizedWalletClient } from 'skychain-decentralized-wallet-js';
import { ChatRoomClient } from 'skychain-chat-js';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import { GameProfileAdminClient } from 'skychain-game-profile-js/lib/admin/game-profile-admin-client';
import { ReportingClient } from 'skychain-reporting-js';
interface SystemState {
	gameProfile: GameProfileAdminClient;
	userIdentity: UserIdentityAdminClient | null;
	metaverseJs: MetaverseInternalClient | null;
	p2pTrading: P2PTradingAdminClient | null;
	decentralizedWallet: DecentralizedWalletClient | null;
	chatRoomClient?: ChatRoomClient;
	gameCenterAdminClient: GameCenterAdminClient;
	isLoading: boolean;
	reportingClient: ReportingClient;
}

const initialState = {
	gameProfile: new GameProfileAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}profile/admin`,
		reportingBaseUrl: `${getEnv('REACT_APP_BASE_URL')}reporting`,
	}),
	userIdentity: new UserIdentityAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}identity/admin`,
	}),
	metaverseJs: new MetaverseInternalClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}metaverse/admin`,
	}),
	p2pTrading: new P2PTradingAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}p2p/admin`,
	}),
	decentralizedWallet: new DecentralizedWalletClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}decentralized-wallet/admin`,
	}),
	gameCenterAdminClient: new GameCenterAdminClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}game-center/admin`,
		reportingBaseUrl: `${getEnv('REACT_APP_BASE_URL')}reporting`,
	}),
	reportingClient: new ReportingClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}reporting`,
	}),
	chatRoomClient: new ChatRoomClient({
		host: `${getEnv('REACT_APP_HOST_NAME')}colyseus`,
		secure: true,
		debug: false,
	}),
	isLoading: false,
} as SystemState;

const systemSlice = createSlice({
	name: 'systemSlice',
	initialState,
	reducers: {
		setTokenId(state, action: PayloadAction<string>) {
			state.userIdentity?.setTokenId(action.payload);
			state.metaverseJs?.setTokenId(action.payload);
			state.p2pTrading?.setTokenId(action.payload);
			state.decentralizedWallet?.setTokenId(action.payload);
			state.chatRoomClient?.setTokenId(action.payload);
			state.gameCenterAdminClient.setTokenId(action.payload);
			state.gameProfile.setTokenId(action.payload);
			state.reportingClient.setTokenId(action.payload);
		},
		setRefreshToken(state, action: PayloadAction<string>) {
			state.userIdentity?.setRefreshToken(action.payload);
		},
		setIsLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
	},
});

export const { setTokenId, setIsLoading, setRefreshToken } =
	systemSlice.actions;
export default systemSlice.reducer;
