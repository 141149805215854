export default function TotalRegisteredIcon(props: any) {
	return (
		<svg
			{...props}
			width={56}
			height={55}
			viewBox="0 0 56 55"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_2464_3131)">
				<g filter="url(#filter1_d_2464_3131)">
					<path
						d="M21.9692 22.7233C21.9692 24.7471 20.3237 26.3912 18.2999 26.3912C16.2761 26.3912 14.6384 24.7464 14.6384 22.7233C14.6384 20.6995 16.2761 19.0547 18.2999 19.0547C20.3237 19.0547 21.9692 20.6995 21.9692 22.7233Z"
						fill="white"
					/>
					<path
						d="M28.4829 25.2842C31.5438 25.2842 34.0252 22.8028 34.0252 19.7419C34.0252 16.6811 31.5438 14.1997 28.4829 14.1997C25.422 14.1997 22.9407 16.6811 22.9407 19.7419C22.9407 22.8028 25.422 25.2842 28.4829 25.2842Z"
						fill="white"
					/>
					<path
						d="M38.6663 19.0547C40.6901 19.0547 42.3349 20.6995 42.3349 22.7233C42.3349 24.7471 40.6901 26.3912 38.6663 26.3912C36.6425 26.3912 35.0049 24.7464 35.0049 22.7233C35.0049 20.6995 36.6425 19.0547 38.6663 19.0547Z"
						fill="white"
					/>
					<path
						d="M46.361 31.4608V33.8786C46.361 34.179 46.175 34.4507 45.8961 34.5494C44.3808 35.1008 42.8139 35.4719 41.2121 35.6665V35.6522V32.2632C41.2199 30.5969 40.5985 28.9957 39.4814 27.7571C40.1394 27.6513 40.7687 27.4146 41.3343 27.0635C43.5505 27.592 46.3467 28.8084 46.361 31.4608Z"
						fill="white"
					/>
					<path
						d="M39.7821 32.2644V35.6534C39.7821 36.2548 39.4031 36.7983 38.8389 36.9978C32.146 39.4007 24.8217 39.4007 18.1288 36.9978C17.5638 36.7983 17.1848 36.2548 17.1848 35.6534V32.2644C17.192 28.2532 21.4398 26.4225 24.7795 25.6416C27.0464 27.0719 29.9284 27.0647 32.1953 25.6416C35.5336 26.4354 39.7671 28.244 39.7821 32.2644Z"
						fill="white"
					/>
					<path
						d="M17.4843 27.7571C16.3687 28.9943 15.7537 30.5969 15.7537 32.2632V35.6522V35.6672C14.1589 35.4734 12.5856 35.1029 11.0767 34.5509C10.7907 34.4507 10.6047 34.179 10.6047 33.8786V31.4608C10.6119 28.8084 13.4223 27.592 15.6321 27.0635C16.197 27.4132 16.8263 27.6492 17.4843 27.7571Z"
						fill="white"
					/>
				</g>
				<rect
					x="2.49097"
					y="0.5"
					width="51.984"
					height={52}
					rx="25.992"
					stroke="white"
					shapeRendering="crispEdges"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_2464_3131"
					x="0.990967"
					y={0}
					width="54.9839"
					height={55}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation="0.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2464_3131"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2464_3131"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_d_2464_3131"
					x="9.60474"
					y="14.1997"
					width="37.7563"
					height="26.6001"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation="0.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2464_3131"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2464_3131"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
