import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '../Icons/CloseIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	PageOptions,
	ReportDto,
	ReportStatus,
	ReportingQueryDto,
} from 'skychain-reporting-js/lib/dto';
import CustomTable from '../UI/CustomTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { format } from 'date-fns';
import { date_format } from '../../config';
import { shortenAddress } from '../../utils';
import LoadingUI from '../UI/LoadingUI';

const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		padding: 0px;
		border-radius: 10px;
		overflow: hidden;
	}
`;

const Content = styled('div')`
	position: relative;
	width: 100%;
	height: max-content;
`;
const CloseWrapper = styled('div')`
	cursor: pointer;
`;
const HeaderWrapper = styled('div')`
	display: flex;
	padding: 15px 20px;
	justify-content: space-between;
	align-items: center;
	background-color: #091229;
	p {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: 0.2px;
		color: #d1d4dc;
		margin: 0;
	}
`;
const BodyWrapper = styled('div')`
	background-color: #070c1e;
	padding: 20px;
`;
const StatusText = styled('p')<{ status: ReportStatus }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		`${
			props.status === ReportStatus.COMPLETED
				? '#03a66d'
				: props.status === ReportStatus.PROCESSING
				? '#23a1b5'
				: '#FFBF1A'
		}`};
`;

interface PropsType {
	isOpen: boolean;
	toggle: () => void;
	query: ReportingQueryDto;
}

const Action = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
export default function ReportModal({ isOpen, toggle, query }: PropsType) {
	const reportClient = useSelector(
		(state: RootState) => state.system.reportingClient
	);
	const [data, setData] = useState<ReportDto[]>([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [paging, setPaging] = useState<PageOptions>({
		page: 1,
		pageSize: 5,
	});
	const columns: ColumnsType<ReportDto> = [
		{
			title: 'Code',
			dataIndex: 'code',
		},
		{
			title: 'Generated At',
			dataIndex: 'generatedAt',
			render: (generatedAt: number) => (
				<>
					{generatedAt ? format(new Date(generatedAt * 1000), date_format) : ''}
				</>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: ReportStatus) => (
				<StatusText status={status}>{status ? 'Success' : 'Fail'}</StatusText>
			),
		},
		{
			title: 'Params',
			dataIndex: 'params',
			render: (params: any) => (
				<>
					{params[1]} - {params[2]}
				</>
			),
		},
		{
			title: 'Download',
			dataIndex: 'downloadUrl',
			render: (url: string) => (
				<>
					{url && (
						<a href={url} target="_blank" rel="noreferrer">
							{shortenAddress(url)}
						</a>
					)}
				</>
			),
		},
	];
	useEffect(() => {
		if (isOpen) {
			getAllReports(query, paging);
		}
	}, [isOpen, query, paging]);

	useEffect(() => {
		if (!isOpen) {
			setData([]);
			setTotal(0);
			setPaging({
				page: 1,
				pageSize: 5,
			});
		}
	}, [isOpen]);
	const getAllReports = async (
		query: ReportingQueryDto,
		paging: PageOptions
	) => {
		try {
			setLoading(true);
			const res = await reportClient.findAllReport(query, paging);
			setData(res.data);
			setTotal(res.meta.totalRecord || 0);
		} catch (error) {
			console.log('getReportingData', error);
		} finally {
			setLoading(false);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setPaging({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 5,
		});
	};
	return (
		<ModalWrapper
			title={null}
			closable={false}
			centered
			open={isOpen}
			onCancel={toggle}
			footer={null}
			width={'100%'}
			style={{
				maxWidth: '1200px',
			}}
		>
			<Content>
				<HeaderWrapper>
					<p>All Reports</p>
					<CloseWrapper onClick={toggle}>
						<CloseIcon />
					</CloseWrapper>
				</HeaderWrapper>
				<BodyWrapper
					style={{
						maxHeight: '70vh',
						overflowY: 'auto',
					}}
				>
					{loading && <LoadingUI />}
					<Action>
						<Button
							onClick={() =>
								setPaging({
									page: 1,
									pageSize: 5,
								})
							}
						>
							Refresh
						</Button>
					</Action>
					<CustomTable
						total={total}
						columns={columns}
						dataSource={data}
						pageSize={paging.pageSize}
						current={paging.page}
						onChange={onChangeTable}
					/>
				</BodyWrapper>
			</Content>
		</ModalWrapper>
	);
}
