export default function UserPaticipationIcon(props: any) {
	return (
		<svg
			{...props}
			width={38}
			height={38}
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.4456 16.3599C14.4456 17.7749 13.2951 18.9244 11.8801 18.9244C10.4651 18.9244 9.32007 17.7744 9.32007 16.3599C9.32007 14.9449 10.4651 13.7949 11.8801 13.7949C13.2951 13.7949 14.4456 14.9449 14.4456 16.3599Z"
				fill="#03A66D"
			/>
			<path
				d="M19 18.1504C21.1401 18.1504 22.875 16.4155 22.875 14.2754C22.875 12.1353 21.1401 10.4004 19 10.4004C16.8599 10.4004 15.125 12.1353 15.125 14.2754C15.125 16.4155 16.8599 18.1504 19 18.1504Z"
				fill="#03A66D"
			/>
			<path
				d="M26.1201 13.7949C27.5351 13.7949 28.6851 14.9449 28.6851 16.3599C28.6851 17.7749 27.5351 18.9244 26.1201 18.9244C24.7051 18.9244 23.5601 17.7744 23.5601 16.3599C23.5601 14.9449 24.7051 13.7949 26.1201 13.7949Z"
				fill="#03A66D"
			/>
			<path
				d="M31.4999 22.47V24.1605C31.4999 24.3705 31.3699 24.5605 31.1749 24.6295C30.1154 25.015 29.0199 25.2745 27.8999 25.4105V25.4005V23.031C27.9054 21.866 27.4709 20.7465 26.6899 19.8805C27.1499 19.8065 27.5899 19.641 27.9854 19.3955C29.5349 19.765 31.4899 20.6155 31.4999 22.47Z"
				fill="#03A66D"
			/>
			<path
				d="M26.9001 23.0309V25.4004C26.9001 25.8209 26.6351 26.2009 26.2406 26.3404C21.5611 28.0204 16.4401 28.0204 11.7606 26.3404C11.3656 26.2009 11.1006 25.8209 11.1006 25.4004V23.0309C11.1056 20.2264 14.0756 18.9464 16.4106 18.4004C17.9956 19.4004 20.0106 19.3954 21.5956 18.4004C23.9296 18.9554 26.8896 20.2199 26.9001 23.0309Z"
				fill="#03A66D"
			/>
			<path
				d="M11.31 19.8805C10.53 20.7455 10.1 21.866 10.1 23.031V25.4005V25.411C8.985 25.2755 7.885 25.0165 6.83 24.6305C6.63 24.5605 6.5 24.3705 6.5 24.1605V22.47C6.505 20.6155 8.47 19.765 10.015 19.3955C10.41 19.64 10.85 19.805 11.31 19.8805Z"
				fill="#03A66D"
			/>
			<rect x="0.5" y="0.5" width={37} height={37} rx="18.5" stroke="#03A66D" />
		</svg>
	);
}
