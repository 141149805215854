import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameProfileAdminClient } from 'skychain-game-profile-js/lib/admin/game-profile-admin-client';
import { IRefSummaryQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import styled from 'styled-components';
import { setIsLoading } from '../../../redux/reducers/system.slice';
import { RootState, useAppDispatch } from '../../../redux/store';
import { formatCurrency } from '../../../utils';
import Copy from '../../UI/Copy';
import CustomTable from '../../UI/CustomTable';
import UpdateLevelModal from './UpdateLevelModal';

const CopyWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 5px;
	p {
		color: #1c84e4;
		font-size: 12px;
		line-height: 16.1px;
	}
`;

interface DataType {
	userId: string;
	userEmail: string;
	inviterEmail: string;
	refCode: string | number;
	totalDownline: string | number;
	totalF1: string | number;
	volumeDownlineCom: string | number;
	cashbackCom: string | number;
	sameLevelCom: string | number;
	vipLevel: string | number;
}

interface FranchiseTableProps {
	query: IRefSummaryQuery;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const FranchiseTable: React.FC<FranchiseTableProps> = ({
	query,
	setParams,
	params,
}: FranchiseTableProps) => {
	const gameProfile = useSelector(
		(state: RootState) => state.system.gameProfile
	);
	const dispatch = useAppDispatch();
	const [summary, setSummary] = React.useState<DataType[]>([]);

	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (gameProfile) {
			getRefSummary(gameProfile, query, params);
		}
	}, [gameProfile, query, params]);

	const handleUpdateLevel = async (id: string, level: number) => {
		try {
			dispatch(setIsLoading(true));
			const res = await gameProfile.updateLevel(id, level);

			if (res) {
				getRefSummary(gameProfile, query, params);
			} else {
				dispatch(setIsLoading(false));
			}
		} catch (error) {
			dispatch(setIsLoading(false));
		}
	};

	const columns: ColumnsType<DataType> = [
		{
			title: 'User',
			dataIndex: 'userEmail',
			key: 'userEmail',
			render: (value) => {
				return (
					<CopyWrapper>
						<p>{value}</p>
						{value && <Copy text={value} />}
					</CopyWrapper>
				);
			},
		},
		{
			title: 'Inviter',
			dataIndex: 'inviterEmail',
			key: 'inviterEmail',
			render: (value) => {
				return (
					<CopyWrapper>
						<p>{value}</p>
						{value && <Copy text={value} />}
					</CopyWrapper>
				);
			},
		},
		{
			title: 'Ref Code',
			dataIndex: 'refCode',
			key: 'refCode',
			render: (value) => {
				return (
					<CopyWrapper>
						<p>{value}</p>
						{value && <Copy text={value} />}
					</CopyWrapper>
				);
			},
		},
		{
			title: 'Total F1',
			dataIndex: 'totalF1',
			key: 'totalF1',
			align: 'center',
		},
		{
			title: 'Total Downline',
			dataIndex: 'totalDownline',
			key: 'totalDownline',
			align: 'center',
		},

		{
			title: 'Volume Downline Commission',
			dataIndex: 'volumeDownlineCom',
			key: 'volumeDownlineCom',
			align: 'center',
		},
		{
			title: 'Cashback Commission',
			dataIndex: 'cashbackCom',
			key: 'cashbackCom',
			align: 'center',
		},
		{
			title: 'Same Level Commission',
			dataIndex: 'sameLevelCom',
			key: 'sameLevelCom',
			align: 'center',
		},
		{
			title: 'Level',
			dataIndex: 'vipLevel',
			key: 'vipLevel',
			align: 'center',
			render: (value) => {
				return <>Level {value}</>;
			},
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => {
				return (
					<UpdateLevelModal
						id={record.userId || ''}
						currentLevel={record.vipLevel}
						handleUpdateLevel={handleUpdateLevel}
					/>
				);
			},
		},
	];

	const getRefSummary = async (
		gameProfile: GameProfileAdminClient,
		query: IRefSummaryQuery,
		params?: PageOptions
	) => {
		dispatch(setIsLoading(true));
		try {
			const res = await gameProfile.getRefSummary(query, params);
			if (res.data) {
				const data: DataType[] = res.data.map((item) => {
					return {
						userId: item.userId,
						userEmail: item.userEmail || '',
						inviterEmail: item.inviterEmail || '',
						refCode: item.refCode || '',
						totalF1: formatCurrency(item.totalF1 || 0, 5),
						sameLevelCom: formatCurrency(item.sameLevelCom || 0, 5),
						cashbackCom: formatCurrency(item.cashbackCom || 0, 5),
						totalDownline: formatCurrency(item.totalDownline || 0, 5),
						volumeDownlineCom: formatCurrency(item.volumeDownlineCom || 0, 5),
						vipLevel: item.vipLevel || '0',
					};
				});

				setSummary(data);
			}

			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('error', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<CustomTable
			columns={columns}
			dataSource={summary}
			onChange={onChangeTable}
			pageSize={params.pageSize}
			total={total}
			current={params.page}
		/>
	);
};

export default FranchiseTable;
