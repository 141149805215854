import { Spin } from 'antd';
import React from 'react';
import { useAppSelector } from '../redux/store';

const LayoutDetailPage = ({ children }: { children: React.ReactNode }) => {
	const isSpinning = useAppSelector((state) => state.system.isLoading);
	return <Spin spinning={isSpinning}>{children}</Spin>;
};

export default LayoutDetailPage;
