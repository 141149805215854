import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, notification } from 'antd';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { ReportingQueryDto } from 'skychain-reporting-js/lib/dto';
import { endOfDay, endOfWeek, format, startOfDay } from 'date-fns';
import CustomInput from '../../UI/CustomInput';
import { PAST_WEEK } from '../../GameManagement/Orders';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useModal from '../../../hooks/useModal';
import ReportModal from '../ReportModal';
import { TWO_YEARS_AGO } from '../../../constants';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;
const Actions = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;
interface Props {
	query: ReportingQueryDto;
	setQuery: (query: ReportingQueryDto) => void;
}

interface FormValues {
	email: string;
	date: any;
}

const GameExperienceReportFilter = ({ query, setQuery }: Props) => {
	const [form] = Form.useForm<FormValues>();
	const { isOpen, toggle } = useModal();
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const reportClient = useSelector(
		(state: RootState) => state.system.reportingClient
	);
	let filterTimeout: any;

	const onFinish = (values: FormValues) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			const fromDate =
				values.date && values.date[0]
					? format(startOfDay(new Date(values.date[0])), 'yyyy-MM-dd') + ''
					: format(new Date(TWO_YEARS_AGO * 1000), 'yyyy-MM-dd');
			const toDate =
				values.date && values.date[1]
					? format(endOfDay(new Date(values.date[1])), 'yyyy-MM-dd') + ''
					: format(endOfWeek(new Date()), 'yyyy-MM-dd');
			setQuery({
				...query,
				params: [`%${values.email || ''}%`, fromDate, toDate],
				page: 1,
			});
		}, 500);
	};

	const generateReport = async () => {
		if (loading) return;
		try {
			setLoading(true);
			await reportClient.generateReport({
				code: query.code,
				params: query.params,
			});

			api.success({
				message: 'Generate report successfully',
			});
			toggle();
		} catch (error) {
			console.log('generateReport', error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		form.setFieldsValue({
			date: [
				dayjs(new Date(query.params[1]), 'YYYY/MM/DD'),
				dayjs(new Date(query.params[2]), 'YYYY/MM/DD'),
			],
		});
	}, [query]);

	return (
		<Wrapper>
			{contextHolder}
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</Filter>
			</Form>
			<Actions>
				<Button type="primary" ghost onClick={generateReport} loading={loading}>
					Export Excel
				</Button>
				<Button type="primary" ghost onClick={toggle}>
					All Reports
				</Button>
			</Actions>
			<ReportModal query={query} isOpen={isOpen} toggle={toggle} />
		</Wrapper>
	);
};

export default GameExperienceReportFilter;
