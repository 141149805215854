import { Button, Form } from 'antd';
import {
	GameStatus,
	GameType,
	PageOptions,
	QueryGame,
} from 'skychain-game-center-js/lib/dto';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';
import CustomSelect from '../../UI/CustomSelect';

interface Props {
	setQuery: (query: QueryGame) => void;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;
const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Statuses = Object.values(GameStatus).map((status) => ({
	value: status,
	label: status,
}));

const GameTypes = Object.values(GameType).map((type) => ({
	value: type,
	label: type,
}));
const Filter = ({ setQuery, params, setParams }: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: QueryGame) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			setParams({ ...params, page: 1 });
			setQuery(values);
		}, 500);
	};
	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<FilterWrapper>
					<CustomInput
						label="Search"
						name={'name'}
						placeHolder="Enter Game Name"
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
					<CustomSelect
						label="Type"
						name={'type'}
						options={GameTypes}
						selectProps={{
							allowClear: true,
							placeholder: 'Game Type',
							style: { width: 200 },
							onChange: () => {
								form.submit();
							},
						}}
					/>
					<CustomSelect
						label="Status"
						name={'status'}
						options={Statuses}
						selectProps={{
							allowClear: true,
							placeholder: 'Supplier Status',
							style: { width: 200 },
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</FilterWrapper>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default Filter;
