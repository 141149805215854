import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { ReportingQueryDto } from 'skychain-reporting-js/lib/dto';
import lodash from 'lodash';
import { format } from 'date-fns';
import { PAST_WEEK } from '../GameManagement/Orders';
import Filter from './Filter';
const Wrapper = styled('div')`
	width: 100%;
	overflow: auto;
`;

export const StatusText = styled('p')<{ status: boolean }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) => `${props.status ? '#03a66d' : '#CF304A'}`};
`;

const Homepage = () => {
	const [query, setQuery] = useState<ReportingQueryDto>({
		code: 'user_total_summary',
		params: [
			'%%',
			format(new Date(PAST_WEEK), 'yyyy-MM-dd'),
			format(new Date(), 'yyyy-MM-dd'),
		],
		page: 1,
		pageSize: 10,
	});
	const reportClient = useSelector(
		(state: RootState) => state.system.reportingClient
	);
	const [dataTable, setDataTable] = useState<any[]>([]);
	const [total, setTotal] = useState(0);

	const columns: ColumnsType<any> = useMemo(() => {
		if (dataTable.length === 0) return [];
		const keys = Object.keys(dataTable[0]);
		return keys.map((key) => ({
			title: lodash.startCase(key),
			dataIndex: key,
			key: key,
		}));
	}, [dataTable]);

	useEffect(() => {
		if (reportClient) {
			getReportingData(query);
		}
	}, [query]);

	const getReportingData = async (query: ReportingQueryDto) => {
		try {
			const res = await reportClient.queryReport(query);
			setDataTable(res.data);
			setTotal(res.meta.totalRecord);
		} catch (error) {
			console.log('getReportingData', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setQuery({
			...query,
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<Wrapper>
			<h3 style={{ marginBottom: '30px' }}>Dashboard</h3>
			<Filter query={query} setQuery={setQuery} />
			<CustomTable
				columns={columns}
				dataSource={dataTable}
				onChange={onChangeTable}
				pageSize={query.pageSize}
				total={total}
				current={query.page}
			/>
		</Wrapper>
	);
};

export default Homepage;
