import { Button, Form } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import { IRefSummaryQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
`;

interface Props {
	setQuery: (query: IRefSummaryQuery) => void;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}

const FranchiseTableFilter: React.FC<Props> = ({
	setParams,
	params,
	setQuery,
}: Props) => {
	const [form] = Form.useForm();

	const onFinish = (values: any) => {
		setParams({ ...params, page: 1 });
		setQuery({ ...values });
		// clearTimeout(filterTimeout);
		// filterTimeout = setTimeout(() => {
		// 	const fromDate = getUnixTime(values.date && new Date(values.date[0]));
		// 	const toDate = getUnixTime(values.date && new Date(values.date[1]));
		// 	setParams({ ...values, fromDate, toDate });
		// }, 500);
	};
	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: debounce(() => {
								form.submit();
							}, 500),
							allowClear: true,
							placeholder: '',
						}}
					/>
					{/* <CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/> */}
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default FranchiseTableFilter;
