import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingContent = styled('div')`
	height: 50vh;
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	position: absolute;
	justify-content: center;
	top: 0;
	bottom: 0;
	z-index: 1030;
`;

const LoadingUI = () => {
	return (
		<LoadingContent>
			<Spin tip="Loading" size="large" />
		</LoadingContent>
	);
};

export default LoadingUI;
