import { Button, Form } from 'antd';
import {
	GameCenterGameOrderQuery,
	PageOptions,
} from 'skychain-game-center-js/lib/dto';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import { END_OF_WEEK, TWO_YEARS_AGO } from '../../../constants';

interface Props {
	setQuery: (query: GameCenterGameOrderQuery) => void;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;
const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Filter = ({ setQuery, params, setParams }: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);
		const fromTime =
			values.date && values.date[0]
				? getUnixTime(startOfDay(new Date(values.date[0]))) + ''
				: getUnixTime(new Date(TWO_YEARS_AGO * 1000));
		const toTime =
			values.date && values.date[1]
				? getUnixTime(endOfDay(new Date(values.date[1]))) + ''
				: END_OF_WEEK;
		filterTimeout = setTimeout(() => {
			setParams({ ...params, page: 1 });
			setQuery({ ...values, fromTime, toTime });
		}, 500);
	};

	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<FilterWrapper>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</FilterWrapper>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default Filter;
