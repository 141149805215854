import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIdentityAdminClient } from 'user-identity-js';
import SendIcon from '../../Icons/SendIcon';
import PaperclipIcon from '../../Icons/PaperclipIcon';
import styled from 'styled-components';
import { ChatRoomClient } from 'skychain-chat-js';
import { RootState } from '../../../redux/store';
import FilerSaver from 'file-saver';
import { GroupChatRoom } from 'skychain-chat-js/lib/room/group.chat';
import { ChatMessage, ChatRoomState } from 'skychain-chat-js/lib/schema/Chat';
import { uniqBy, find } from 'lodash';
import { shortenEmail } from '../../../utils';
import { UserEntity } from 'user-identity-js/lib/entities';

interface ItemChat {
	id: string;
	uid: string;
	nickName: string;
	email: string;
	message: string;
	timestamp: number;
	avatar: string;
	type: string;
}

const Wrapper = styled('div')`
	width: 356px;
	p {
		margin: 0;
	}
`;
const Header = styled('div')`
	background-color: #091229;
	padding: 20px;
	border-radius: 10px 10px 0px 0px;
`;
const UserWrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Content = styled('div')`
	height: 450px;
	background-color: #070c1e;
	padding: 20px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
const ChatBox = styled('div')`
	display: flex;
	padding: 14px 20px;
	align-items: center;
	border-radius: 0px 0px 10px 10px;
	background-color: #091229;
`;
const EmailOnRoom = styled('p')`
	margin: 0;
	font-size: 12px;
	color: #737283;
`;
const ReceiveWrapper = styled('div')`
	display: flex;
	flex-direction: column;
`;
const ReceiveContent = styled('div')`
	padding: 16px;
	background-color: rgba(255, 195, 31, 0.1);
	border-radius: 8px;
	font-size: 14px;
	line-height: 28px;
	cursor: pointer;
	width: max-content;
	max-width: calc(100% - 40px);
`;
const SendWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
`;
const SendContent = styled('div')<{ type: string }>`
	padding: 16px;
	background-color: #1f2434;
	border-radius: 8px;
	font-size: 14px;
	line-height: 28px;
	cursor: pointer;
	width: max-content;
	max-width: calc(100% - 40px);
	word-wrap: break-word; /* IE 5.5-7 */
	white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
	white-space: pre-wrap; /* current browsers */
	text-decoration: ${(props) => (props.type === 'file' ? 'underline' : 'none')};
`;
const Mail = styled('p')`
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
	width: 100%;
	word-wrap: break-word; /* IE 5.5-7 */
	white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
	white-space: pre-wrap; /* current browsers */
`;
const UserInfo = styled('div')`
	width: 60%;
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 10px;
`;
const AvatarWrapper = styled('div')`
	position: relative;
	display: flex;
	justify-content: center;
`;
const TargetUserAvatar = styled('img')`
	margin-left: -12px;
	border-radius: 100%;
	width: 28px;
	height: 28px;
	/* position: absolute; */
`;
const UserAvatar = styled('img')`
	border-radius: 100%;
	position: relative;
	z-index: 2;
	border: 2px solid #091229;
	width: 28px;
	height: 28px;
	/* position: absolute; */
`;
const SendIconWrapper = styled('div')`
	cursor: pointer;
`;
const PaperClipWrapper = styled('div')`
	margin-right: 16px;
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
`;
const StatusWrapper = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: flex-end;
`;
const StatusIcon = styled('div')<{ status: string }>`
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-right: 5px;
	background-color: ${(props) =>
		`${props.status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}`};
`;
const StatusText = styled('p')<{ status: string }>`
	font-size: 14px;
	color: ${(props) =>
		`${props.status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}`};
`;
const MessageInput = styled('input')`
	background-color: transparent;
	border: none;
	outline: none;
	color: #ffffff;
	width: 100%;
`;

interface Props {
	targetedUser: UserEntity | undefined;
	status: string | undefined;
	groupId: string;
	user: UserEntity;
}
const Chat = ({ targetedUser, user, status, groupId }: Props) => {
	const chatRoomClient = useSelector(
		(state: RootState) => state.system.chatRoomClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const userProfile = useSelector((state: RootState) => state.auth.account);
	// const toast = useToast();
	const [chatRoom, setChatRoom] = useState<GroupChatRoom<ChatRoomState>>();
	const [chatList, setChatList] = useState<ItemChat[]>([]);
	const [chatIsReady, setChatIsReady] = useState(false);
	const inputRef = useRef<any>();
	const chatContainerRef = useRef<any>();

	useEffect(() => {
		if (chatRoomClient && groupId) {
			listenEventChat(chatRoomClient, groupId);
		}
	}, [chatRoomClient, groupId]);

	useEffect(() => {
		if (chatIsReady && chatRoom && userIdentity) {
			getHistory(chatRoom, userIdentity);
		}
	}, [chatIsReady, chatRoom, userIdentity]);

	const listenEventChat = async (
		chatRoomClient: ChatRoomClient,
		groupId: string
	) => {
		const chatRoom = chatRoomClient.getGroupChatRoom(groupId);
		await chatRoom.connect();
		setChatRoom(chatRoom);

		chatRoom.onError((code, error) => {
			console.log('error', error);
			// toast({
			// 	title: 'Error',
			// 	description: error,
			// 	status: 'error',
			// });
		});

		chatRoom.onStateChange((state) => {
			setChatIsReady(state.isReady);
		});

		chatRoom.onChatMessage((message) => {
			parseChatMesage(message);
		});
	};

	const parseChatMesage = async (message: ChatMessage) => {
		if (!userIdentity) return;
		try {
			const user = await userIdentity.getUser(message.uid);
			const newMessage = {
				id: message.id,
				uid: message.uid,
				nickName: user?.nickName || 'Hidden',
				email: user?.email,
				message: message.message,
				timestamp: message.timestamp * 1000,
				avatar: user.avatar || '/images/avatar.png',
				type: message.type,
			} as ItemChat;
			setChatList((prevChat) => {
				let newList = prevChat.concat([newMessage]);
				newList = uniqBy(newList, 'id');
				return newList;
			});
			scrollToBottomChat();
		} catch (error) {
			console.log('error', error);
		}
	};

	const getHistory = async (
		chatRoom: GroupChatRoom<ChatRoomState>,
		userIdentity: UserIdentityAdminClient
	) => {
		try {
			const queryParams = {
				limit: 100,
				offset: 0,
			};
			const history = await chatRoom.getChatHistory(queryParams);
			if (history.length > 0) {
				const users = await userIdentity.getUsers({
					ids: history
						.reverse()
						.map((historyItem) => historyItem.uid)
						.toString(),
				});
				const newChatList = history.map((historyItem) => {
					return {
						id: historyItem.id,
						uid: historyItem.uid,
						nickName:
							find(users.data, { id: historyItem.uid })?.nickName || 'Hidden',
						email: find(users.data, { id: historyItem.uid })?.email || 'Hidden',
						message: historyItem.message,
						timestamp: historyItem.timestamp * 1000,
						avatar:
							find(users.data, { id: historyItem.uid })?.avatar ||
							'/images/avatar.svg',
						type: historyItem.type,
					};
				});
				setChatList(newChatList);
				scrollToBottomChat();
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleSendMessage = async () => {
		if (inputRef.current) {
			const message = inputRef.current?.value || '';
			if (chatRoom?.isReady() && message) {
				await chatRoom.sendTextMessage(message);
				inputRef.current.value = '';
			}
		}
	};
	const handleSendFile = async (files: FileList | null) => {
		if (files && files.length > 0) {
			await chatRoom?.sendFileMessage(files[0]);
		}
	};
	const scrollToBottomChat = async () => {
		const scroll = chatContainerRef.current?.scrollHeight;
		chatContainerRef.current?.scrollTo(0, scroll + 600);
	};
	const getFileToDisplay = async (
		fileId: string,
		groupId: string,
		messageId: string
	) => {
		const res = await chatRoom?.getFile(fileId, groupId);
		if (res) {
			const index = chatList.findIndex((item) => item.id === messageId);
			if (index !== -1) {
				FilerSaver.saveAs(res.url, fileId);
			}
		}
	};

	return (
		<Wrapper>
			<Header>
				<UserWrapper>
					<UserInfo>
						<AvatarWrapper>
							<UserAvatar
								src={user?.avatar || '/images/avatar.svg'}
								alt="avatar"
							/>
							<TargetUserAvatar
								src={targetedUser?.avatar || '/images/avatar.svg'}
								alt="avatar"
							/>
						</AvatarWrapper>

						<Mail>
							{shortenEmail(targetedUser?.email || '')},<br />
							{shortenEmail(user?.email || '')}
						</Mail>
					</UserInfo>
					<div>
						<StatusWrapper>
							{status && (
								<>
									<StatusIcon status={status} />
									<StatusText status={status}>
										{status?.charAt(0)?.toUpperCase() + status?.slice(1)}
									</StatusText>
								</>
							)}
						</StatusWrapper>
					</div>
				</UserWrapper>

				{/* <SubInfoWrapper>
					<SubInfo>
						30D Trades: <span>1336</span>
					</SubInfo>
					<SubInfo>
						30D Completion Rate: <span>100%</span>
					</SubInfo>
				</SubInfoWrapper> */}
			</Header>
			<Content ref={chatContainerRef}>
				{chatList.map((item) =>
					item.uid === userProfile?.id ? (
						<SendWrapper
							key={item.id}
							onClick={async () => {
								item.type === 'file' &&
									(await getFileToDisplay(item.message, groupId, item.id));
							}}
						>
							<SendContent type={item.type}>{item.message}</SendContent>
						</SendWrapper>
					) : (
						<ReceiveWrapper key={item.id}>
							<EmailOnRoom>{item.email}</EmailOnRoom>
							<ReceiveContent
								onClick={async () => {
									item.type === 'file' &&
										(await getFileToDisplay(item.message, groupId, item.id));
								}}
							>
								{item.message}
							</ReceiveContent>
						</ReceiveWrapper>
					)
				)}
			</Content>
			<ChatBox>
				<MessageInput
					ref={inputRef}
					placeholder={'Write your message...'}
					onKeyDown={(event) => event.key === 'Enter' && handleSendMessage()}
				/>
				<PaperClipWrapper>
					<PaperclipIcon style={{ cursor: 'pointer' }} />
					<input
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							opacity: 0,
							zIndex: 1,
							cursor: 'pointer',
						}}
						width={20}
						height={20}
						type="file"
						onChange={(event) => handleSendFile(event.target.files)}
					/>
				</PaperClipWrapper>
				<SendIconWrapper onClick={handleSendMessage}>
					<SendIcon />
				</SendIconWrapper>
			</ChatBox>
		</Wrapper>
	);
};

export default Chat;
