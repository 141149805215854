import React, { useState } from 'react';
import TransactionTable from './TransactionTable';
import TransactionFilter from './TransactionFilter';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const Transaction = () => {
	const [query, setQuery] = useState<TransactionQueryRequest>();
	const [params, setParams] = useState({
		page: 1,
		pageSize: 10,
	});
	return (
		<div>
			<TransactionFilter
				setQuery={setQuery}
				params={params}
				setParams={setParams}
			/>
			<TransactionTable query={query} params={params} setParams={setParams} />
		</div>
	);
};

export default Transaction;
