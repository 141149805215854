import React, { useState } from 'react';
import styled from 'styled-components';
import CommissionHistoryFilter from './Filter';
import CommissionHistoryTable from './Table';
import { IRefCommissionListQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import { END_OF_WEEK, TWO_YEARS_AGO } from '../../../constants';

const Wrapper = styled('div')`
	margin-top: 10px;
`;

export default function CommissionHistory() {
	const [query, setQuery] = useState<IRefCommissionListQuery>({
		fromTime: TWO_YEARS_AGO,
		toTime: END_OF_WEEK,
	});
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
		// order: PageOrder.DESC,
	});
	return (
		<Wrapper>
			<CommissionHistoryFilter
				setParams={setParams}
				params={params}
				setQuery={setQuery}
			/>
			<CommissionHistoryTable
				params={params}
				query={query}
				setParams={setParams}
			/>
		</Wrapper>
	);
}
