import styled from 'styled-components';
import { GAME_MANAGEMENT_TABS } from '../../constants/tabs';
import { useEffect, useState } from 'react';
import { GameCenterSummaryReport } from 'skychain-game-center-js/lib/dto';
import { useSelector } from 'react-redux';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	TotalOrders,
	UserParticipation,
	TotalVolume,
} from '../../components/GameManagement/styles';
import TotalOrdersIcon from '../../components/Icons/TotalOrdersIcon';
import TotalVolumeIcon from '../../components/Icons/TotalVolumeIcon';
import UserParticipationIcon from '../../components/Icons/UserParticipationIcon';
import { RootState } from '../../redux/store';
import { formatCurrency } from '../../utils';
import TabsUI from '../../components/UI/Tabs';
import { Outlet } from 'react-router-dom';

const GeneralInfo = styled.div`
	/* position: absolute; */
	/* top: -10px; */
	/* right: 0; */
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 30px;
	margin-bottom: 10px;
	flex-wrap: wrap;
`;
const GeneralInfoItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const GameManagementLayout = () => {
	const [summary, setSummary] = useState<GameCenterSummaryReport>();
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);

	useEffect(() => {
		getSummary(gameCenterAdminClient);
	}, [gameCenterAdminClient]);

	const getSummary = async (gameCenterAdminClient: GameCenterAdminClient) => {
		if (!gameCenterAdminClient) return;
		try {
			const res = await gameCenterAdminClient.getGameCenterSummaryReport();
			setSummary(res);
		} catch (error) {
			console.log('getSummary', error);
		}
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				position: 'relative',
			}}
		>
			<GeneralInfo>
				<GeneralInfoItem>
					<TotalOrdersIcon />
					<TotalOrders>
						{formatCurrency(summary?.totalOrder)} Total Orders
					</TotalOrders>
				</GeneralInfoItem>
				<GeneralInfoItem>
					<UserParticipationIcon />
					<UserParticipation>
						{formatCurrency(summary?.totalUser)} User Participation
					</UserParticipation>
				</GeneralInfoItem>
				<GeneralInfoItem>
					<TotalVolumeIcon />
					<TotalVolume>
						${formatCurrency(summary?.totalVolume)} Total Volume
					</TotalVolume>
				</GeneralInfoItem>
			</GeneralInfo>
			<TabsUI tabs={GAME_MANAGEMENT_TABS} />

			<Outlet />
		</div>
	);
};

export default GameManagementLayout;
