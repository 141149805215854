import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export interface PropsType {
	tabs: TabItem[];
}

const TabContainer = styled('div')`
	position: relative;
	width: 100%;
	border-bottom: 1px solid #ffffff1e;
`;

const TabList = styled('ul')`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	justify-content: flex-start;
	gap: 10px;
`;
const TabItem = styled('li')<{ active: boolean }>`
	padding: 10px 10px 20px 0px;
	font-size: 14px;
	cursor: pointer;
	color: ${(props) => (props.active ? 'white' : '#737283')};
	text-transform: capitalize;
	transition: 0.3s ease-in-out;
`;

// const TabInkBar = styled('div')`
// 	position: absolute;
// 	bottom: 0;
// 	background: #ffe036;
// 	border-radius: 14px 14px 0px 0px;
// 	height: 2px;
// 	transition: 0.3s ease-in-out;
// `;

const TabsUI = ({ tabs }: PropsType) => {
	// const [offsetWidthTab, setOffsetWidthTab] = useState<number>(0);
	// const [offsetLeftTab, setOffsetLeftTab] = useState<number>(0);
	const [activeTab, setActiveTab] = useState<TabItem>();
	const tabRef = useRef<HTMLLIElement[]>([]);
	const location = useLocation();
	const navigate = useNavigate();
	const onClickTab = (e: any, tab: TabItem) => {
		// setOffsetWidthTab(e.currentTarget.offsetWidth);
		// setOffsetLeftTab(e.currentTarget.offsetLeft);
		setActiveTab(tab);
	};
	useEffect(() => {
		const tab = tabs.find((tab) => location.pathname.includes(tab.url));
		if (!tab) {
			navigate(tabs[0].url || '');
		} else {
			setActiveTab(tab);
		}
	}, [location.pathname, tabs]);

	// useEffect(() => {
	// 	if (tabRef.current[0]) {
	// 		// setOffsetWidthTab(tabRef.current[0].offsetWidth);
	// 		// setOffsetLeftTab(tabRef.current[0].offsetLeft);
	// 		setActiveTab(tabs[0]);
	// 	}
	// }, [tabRef.current[0]]);

	return (
		<TabContainer>
			<TabList>
				{tabs.map((tab) => {
					const active = activeTab?.key === tab.key;
					return (
						<Link
							style={{
								textDecoration: 'none',
								borderBottom: active ? '1px solid #ffe036' : 'none',
							}}
							key={tab.key}
							to={tab.url}
						>
							<TabItem
								active={active}
								ref={(ref) => ref && tabRef.current.push(ref)}
								onClick={(e) => onClickTab(e, tab)}
							>
								{tab.value}
							</TabItem>
						</Link>
					);
				})}
			</TabList>
			{/* <TabInkBar
				style={{
					width: `${offsetWidthTab}px`,
					left: `${offsetLeftTab}px`,
				}}
			/> */}
		</TabContainer>
	);
};

export default TabsUI;
