import styled from 'styled-components';
import TotalRegisteredIcon from '../../Icons/TotalRegisterdIcon';
import TotalUSDTIcon from '../../Icons/TotalUSDTIcon';
import TotalValidPackageIcon from '../../Icons/TotalValidPackageIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import { GameExperienceSummaryReport } from 'skychain-game-center-js/lib/dto/game-experience.dto';
import { useEffect, useState } from 'react';
import { formatCurrency } from '../../../utils';

const GeneralInfo = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
	@media screen and (max-width: 576px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const TotalUSDT = styled.div`
	min-height: 94px;
	background: linear-gradient(270deg, #8b6be6 -30.6%, #e2589a 134.33%);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 10px;
	padding: 20px 30px;
	display: flex;
	gap: 30px;
	box-sizing: border-box;
`;
const TotalRegistered = styled.div`
	min-height: 94px;
	background: linear-gradient(270deg, #37cbff -30.6%, #0094db 133.58%);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 10px;
	padding: 20px 30px;
	display: flex;
	gap: 30px;
	box-sizing: border-box;
`;
const TotalValidPackage = styled.div`
	min-height: 94px;
	background: linear-gradient(270deg, #78bd44 -34.33%, #29a961 135.68%);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 10px;
	padding: 20px 30px;
	display: flex;
	gap: 30px;
	box-sizing: border-box;
`;
const Info = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	div {
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
		color: #ffffff;
		line-height: 28px;
	}
`;
const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
`;
const Value = styled.div`
	font-weight: 600;
	font-size: 22px;
`;
const GeneralInfoHeader = () => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const [gameTrialInfo, setGameTrialInfo] =
		useState<GameExperienceSummaryReport>();

	useEffect(() => {
		getGameTrialInfo(gameCenterAdminClient);
	}, [gameCenterAdminClient]);

	const getGameTrialInfo = async (
		gameCenterAdminClient: GameCenterAdminClient
	) => {
		if (!gameCenterAdminClient) return;
		try {
			const res = await gameCenterAdminClient.getGameExperienceSummaryReport();
			setGameTrialInfo(res);
		} catch (error) {
			console.log('getGameTrialInfo', error);
		}
	};
	return (
		<GeneralInfo>
			<TotalUSDT>
				<TotalUSDTIcon />
				<Info>
					<Title>Total USDT Refund</Title>
					<Value>
						${formatCurrency(gameTrialInfo?.totalCashbackSucceedAmount || 0)}
					</Value>
				</Info>
			</TotalUSDT>
			<TotalRegistered>
				<TotalRegisteredIcon />
				<Info>
					<Title>Total Registered User </Title>
					<Value>{formatCurrency(gameTrialInfo?.totalRegisteredUser)}</Value>
				</Info>
				<Info>
					<Title>Today Registered </Title>
					<Value>{formatCurrency(gameTrialInfo?.todayRegisteredUser)}</Value>
				</Info>
			</TotalRegistered>
			<TotalValidPackage>
				<TotalValidPackageIcon />
				<Info>
					<Title>Total Valid Package</Title>
					<Value>
						{formatCurrency(gameTrialInfo?.totalValidPackage)}/
						{formatCurrency(gameTrialInfo?.totalPackage)}
					</Value>
				</Info>
				<Info>
					<Title>Today Valid Package </Title>
					<Value>
						{formatCurrency(gameTrialInfo?.todayValidPackage)}/
						{formatCurrency(gameTrialInfo?.todayPackage)}
					</Value>
				</Info>
			</TotalValidPackage>
		</GeneralInfo>
	);
};

export default GeneralInfoHeader;
