import React from 'react';
import styled from 'styled-components';
import { AdDetail } from '.';
import { format } from 'date-fns';

const BodyWrapper = styled('div')`
	width: 100%;
	max-width: 1056px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	p {
		margin: 0;
	}
`;
const InfoRow = styled('div')`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;
const FlexLeft = styled('div')`
	width: 257px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		color: #737283;
	}
`;
const FlexRight = styled('div')`
	width: calc(100% - 257px);
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;
const Mail = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
`;
const OptionalContent = styled('p')`
	font-size: 14px;
`;
const PaymentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
const BankWrapper = styled('div')`
	position: relative;
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 387px;
`;
const UserName = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
`;
const BankInfo = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
`;

interface Props {
	data: AdDetail;
}
export default function Body({ data }: Props) {
	return (
		<BodyWrapper>
			<InfoRow>
				<FlexLeft>
					<p>User:</p>
				</FlexLeft>
				<FlexRight>
					<Mail>{data.userInfo.email}</Mail>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Price:</p>
				</FlexLeft>
				<FlexRight>
					<p>
						{data.priceValue} {data.fiatSymbol}
					</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Total Amount:</p>
				</FlexLeft>
				<FlexRight>
					<p>
						{data.totalAmount} {data.assetSymbol}
					</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Order Limit:</p>
				</FlexLeft>
				<FlexRight>
					<p>
						{data.minAmount} {data.fiatSymbol} - {data.maxAmount}{' '}
						{data.fiatSymbol}
					</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Created Date:</p>
				</FlexLeft>
				<FlexRight>
					<p>{format(new Date(data.createdAt * 1000), 'yyyy-MM-dd HH:mm a')}</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Pament Method:</p>
				</FlexLeft>
				<FlexRight>
					<PaymentWrapper>
						<BankWrapper>
							<BankInfo>{data.paymentMethodInfo?.bankName}</BankInfo>
							<UserName>{data.paymentMethodInfo?.fullName}</UserName>
							<BankInfo>{data.paymentMethodInfo?.bankAccountNumber}</BankInfo>
						</BankWrapper>
					</PaymentWrapper>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Payment Time Limit:</p>
				</FlexLeft>
				<FlexRight>
					<p>{data.timeLimit} Mins</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Term :</p>
				</FlexLeft>
				<FlexRight>
					<OptionalContent>{data.term}</OptionalContent>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Auto - Reply :</p>
				</FlexLeft>
				<FlexRight>
					<OptionalContent>
						<p>{data.autoReply}</p>
					</OptionalContent>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Available Region(s):</p>
				</FlexLeft>
				<FlexRight>
					<p>{data.regions}</p>
				</FlexRight>
			</InfoRow>

			<InfoRow>
				<FlexLeft>
					<p>Status</p>
				</FlexLeft>
				<FlexRight>
					<p>{data.status}</p>
				</FlexRight>
			</InfoRow>
		</BodyWrapper>
	);
}
