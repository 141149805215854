import { DatePicker, Form, FormItemProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import styled from 'styled-components';
import { date_range_format } from '../../config';
const { RangePicker } = DatePicker;
const Label = styled('div')`
	color: #737283;
`;

const StyledRangePicker = styled(RangePicker)`
	input::placeholder {
		color: #cf304a;
	}
	.ant-picker-input > input {
		color: #fff;
	}
	.ant-picker-suffix {
		color: #fffcfc40 !important;
	}
`;
interface CustomRangePickerProps extends Omit<FormItemProps, 'label'> {
	label?: string;
	dateProps?: RangePickerProps;
}
const CustomRangePicker = ({
	label,
	dateProps,
	...props
}: CustomRangePickerProps) => {
	return (
		<Form.Item label={<Label>{label}</Label>} {...props}>
			<StyledRangePicker
				size="large"
				style={{
					width: '100%',
					background: 'rgba(255, 255, 255, 0.05)',
					border: '1px solid rgba(255, 255, 255, 0.08)',
					color: '#fff',
				}}
				format={date_range_format}
				{...dateProps}
			/>
		</Form.Item>
	);
};

export default CustomRangePicker;
