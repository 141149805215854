import styled from 'styled-components';
import PrimaryButton from '../UI/PrimaryButton';
import { Input } from 'antd';

export const LoginWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	overflow-y: auto;
	width: 100%;
	color: #ffffff;
	background-color: #050713;
`;
export const BackgroundContainer = styled.div`
	width: 100%;
	height: calc(100vh - 70px);
	background-image: url('/images/login-bg.svg');
	display: flex;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const Container = styled.div`
	width: 1070px;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 20px;
	gap: 40px;
`;

export const LeftBlock = styled.div`
	[class*='ant-form-item-label'] {
		padding: 0;
	}
	@media screen and (max-width: 576px) {
		width: 100%;
		margin-top: 30px;
	}
`;

export const RightBlock = styled.img`
	flex: 1;
	width: 100%;
	height: 100%;
	object-fit: contain;
	max-width: 624px;
	@media screen and (max-width: 576px) {
		max-height: 30vh;
	}
`;
export const LoginBtn = styled(PrimaryButton)`
	border-radius: 36px;
	width: 400px;
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;

export const Logo = styled.img`
	height: 50px;
	width: auto;
	margin-bottom: 40px;
	@media screen and (max-width: 576px) {
		margin-bottom: 20px;
	}
`;
export const Title = styled.div`
	font-size: 18px;
	line-height: 28px;
	margin-bottom: 20px;
`;

export const Label = styled.div`
	color: #bab9d0;
	font-size: 16px;
	line-height: 24px;
`;

export const StyledInput = styled(Input)`
	height: 48px;
	background: #ffffff;
	border-radius: 36px;
	width: 400px;
	margin-top: 13px;
	box-sizing: border-box;
	padding: 14px 20px;
	color: #000000;
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;

export const StyledInputPassword = styled(Input.Password)`
	height: 48px;
	background: #ffffff;
	border-radius: 36px;
	width: 400px;
	margin-top: 13px;
	box-sizing: border-box;
	padding: 14px 20px;
	input {
		background: #ffffff;
		color: #000000;
	}
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;
export const Footer = styled.div`
	background: rgba($color: #091229, $alpha: 0.5);
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #bdbcc7;
	width: 100%;
`;
