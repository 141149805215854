import styled from 'styled-components';
import GeneralInfoHeader from './GeneralInfoHeader';
import { useState } from 'react';
import { QueryGameExRegisterDto } from 'skychain-game-center-js/lib/dto/game-experience.dto';
import GameTrialRegisteredTable from './GameTrialRegisteredTable';
import { PageOptions } from 'skychain-game-center-js/lib/dto';
import Filter from './Filter';

const Retangle = styled.div`
	height: 1px;
	width: 100%;
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	margin: 20px 0;
`;
const GameTrialPackage = () => {
	const [query, setQuery] = useState<QueryGameExRegisterDto>();
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	return (
		<div style={{ position: 'relative' }}>
			<GeneralInfoHeader />
			<Retangle />
			<Filter
				query={query}
				setQuery={setQuery}
				params={params}
				setParams={setParams}
			/>
			<GameTrialRegisteredTable
				query={query}
				params={params}
				setParams={setParams}
			/>
		</div>
	);
};

export default GameTrialPackage;
