import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useEffect, useState } from 'react';
import { MetaverseInternalClient } from 'metaverse-js/lib/internal/MetaverseInternalClient';
import { ColumnsType } from 'antd/es/table';
import CustomTable from '../UI/CustomTable';
import EditIcon from '../Icons/EditIcon';
import styled from 'styled-components';
import { AccountCurrencyConfig } from 'metaverse-js/lib/proto/model/wallet';
import UpdateUserConfigModal from './UpdateUserConfigModal';
import { Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import CreateUserCurrencyConfigModal from './CreateUserCurrencyConfigModal';
import ConfirmModal from './ConfirmModal';
import { setIsLoading } from '../../redux/reducers/system.slice';

const EditButton = styled.div`
	cursor: pointer;
	&:hover {
		svg path {
			fill: yellow;
		}
	}
`;

interface Params {
	page: number;
	pageSize: number;
}
const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 30px;
`;
const UserCurrencyConfig = () => {
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const dispatch = useAppDispatch();
	const [configs, setConfigs] = useState<AccountCurrencyConfig[]>([]);
	const [selectedConfig, setSelectedConfig] = useState<AccountCurrencyConfig>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [total, setTotal] = useState(0);
	const [params, setParams] = useState<Params>({
		page: 1,
		pageSize: 10,
	});
	const [openAddNewConfigModal, setOpenAddNewConfigModal] =
		useState<boolean>(false);
	const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

	const columns: ColumnsType<AccountCurrencyConfig> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'User ID',
			dataIndex: 'userId',
			key: 'id',
		},
		{
			title: 'Currency',
			dataIndex: 'symbol',
			key: 'symbol',
		},
		{
			title: 'Max per one day',
			dataIndex: 'maxPerOneDay',
			key: 'maxPerOneDay',
			render: (text) => <>{text || '-'}</>,
		},
		{
			title: 'Min per one order',
			dataIndex: 'minPerOneOrder',
			key: 'minPerOneOrder',
			render: (text) => <>{text || '-'}</>,
		},
		{
			title: 'Threshold',
			dataIndex: 'thresholdToReview',
			key: 'thresholdToReview',
			render: (text) => <>{text || '-'}</>,
		},
		{
			title: 'Flat fee',
			dataIndex: 'flatFee',
			key: 'flatFee',
			render: (text) => <>{text || '-'}</>,
		},

		{
			title: 'Transaction fee',
			dataIndex: 'transactionFeePercent',
			key: 'transactionFeePercent',
			render: (text) => <>{text ? <div>{text} %</div> : '-'}</>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text) => (
				<div style={{ color: text === 'ENABLED' ? 'green' : '#cf304a' }}>
					{text === 'ENABLED' ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<div style={{ display: 'flex', gap: 10 }}>
					<EditButton
						onClick={() => {
							setSelectedConfig(record);
							setOpenModal(true);
						}}
					>
						<EditIcon />
					</EditButton>
					<EditButton
						onClick={() => {
							setSelectedConfig(record);
							setOpenConfirmModal(true);
						}}
					>
						<DeleteOutlined
							style={{
								fontSize: 18,
								color: 'rgb(115, 114, 131)',
							}}
						/>
					</EditButton>
				</div>
			),
		},
	];

	useEffect(() => {
		getUsersCurrencyConfig(metaverseJs, params);
	}, [metaverseJs, params]);

	const getUsersCurrencyConfig = async (
		metaverseJs: MetaverseInternalClient | null,
		params: Params
	) => {
		if (!metaverseJs) return;
		dispatch(setIsLoading(true));
		try {
			const res = await metaverseJs.getAccountCurrencyConfigs({
				limit: params.pageSize,
				offset: (params.page - 1) * params.pageSize,
			});
			setTotal(res.meta.total);
			setConfigs(res.data);
			dispatch(setIsLoading(false));
		} catch (error) {
			console.log('getUsersCurrencyConfig', error);
			dispatch(setIsLoading(false));
		}
	};

	return (
		<div style={{ marginTop: 30 }}>
			<Header>
				<Button
					ghost
					type="primary"
					onClick={() => setOpenAddNewConfigModal(true)}
				>
					<PlusOutlined /> Add New User Config
				</Button>
			</Header>
			<CustomTable
				onChange={(pagination) => {
					setParams({
						page: pagination.current || 1,
						pageSize: pagination.pageSize || 10,
					});
				}}
				total={total}
				dataSource={configs}
				columns={columns}
				pageSize={params.pageSize}
			/>
			<UpdateUserConfigModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				configDetail={selectedConfig}
				refresh={() => getUsersCurrencyConfig(metaverseJs, params)}
			/>
			<CreateUserCurrencyConfigModal
				open={openAddNewConfigModal}
				onClose={() => setOpenAddNewConfigModal(false)}
				refresh={() => getUsersCurrencyConfig(metaverseJs, params)}
			/>
			<ConfirmModal
				open={openConfirmModal}
				onClose={() => setOpenConfirmModal(false)}
				refresh={() => getUsersCurrencyConfig(metaverseJs, params)}
				configId={selectedConfig?.id}
			/>
		</div>
	);
};

export default UserCurrencyConfig;
