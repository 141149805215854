import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KRYPTO8_ADMIN } from '../../constants';

interface AuthState {
	account: Account | null;
}

const initialState = {
	account: null,
} as AuthState;

const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		setAccount: (state, action: PayloadAction<Account>) => {
			state.account = action.payload;
		},
		logout: (state) => {
			state.account = null;
			localStorage.removeItem(KRYPTO8_ADMIN);
		},
	},
});

export const { setAccount, logout } = authSlice.actions;
export default authSlice.reducer;
