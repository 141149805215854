export default function TotalOrdersIcon(props: any) {
	return (
		<svg
			{...props}
			width={38}
			height={38}
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.6095 11.4495C23.4491 11.1723 23.1506 11 22.8305 11H19.4849V14.9574H25.6384L23.6095 11.4495Z"
				fill="#0092C0"
			/>
			<path
				d="M18.5469 11H15.1697C14.849 11 14.5504 11.1726 14.3901 11.4505L12.3687 14.9574H18.5469V11H18.5469Z"
				fill="#0092C0"
			/>
			<path
				d="M12.033 15.8955V25.8977C12.033 26.5057 12.5278 27.0003 13.1359 27.0003H24.864C25.472 27.0003 25.9669 26.5057 25.9669 25.8977V15.8955H12.033ZM21.1285 20.1018L18.7032 22.527C18.6116 22.6186 18.4916 22.6646 18.3716 22.6646C18.2515 22.6646 18.1315 22.6186 18.0402 22.527L16.9032 21.3904C16.7203 21.2075 16.7203 20.9105 16.9032 20.7273C17.0864 20.5441 17.3834 20.5441 17.5666 20.7273L18.3716 21.5326L20.4655 19.4387C20.6487 19.2555 20.9457 19.2555 21.1286 19.4387C21.3117 19.6219 21.3117 19.9186 21.1285 20.1018Z"
				fill="#0092C0"
			/>
			<rect x="0.5" y="0.5" width={37} height={37} rx="18.5" stroke="#0092C0" />
		</svg>
	);
}
