import { Outlet } from 'react-router-dom';
import TabsUI from '../../components/UI/Tabs';

interface Props {
	tabs: TabItem[];
}
const LayoutTab = ({ tabs }: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
			}}
		>
			<TabsUI tabs={tabs} />
			<Outlet />
		</div>
	);
};

export default LayoutTab;
