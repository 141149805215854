export default function TotalVolumeIcon(props: any) {
	return (
		<svg
			{...props}
			width={38}
			height={38}
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_938_13629)">
				<path
					d="M26.4063 20.8832C26.4063 21.7386 26.2895 22.5672 26.0719 23.3545C27.86 21.8643 29 19.6217 29 17.1175C29 12.6417 25.3586 9.00035 20.8828 9.00035C18.3787 9.00035 16.1361 10.1404 14.6459 11.9284C15.4331 11.7109 16.2617 11.5941 17.1172 11.5941C22.2392 11.5941 26.4062 15.7612 26.4063 20.8832Z"
					fill="url(#paint0_linear_938_13629)"
				/>
				<path
					d="M18.8008 20.8828L17.1193 22.5643L15.4378 20.8828L17.1193 19.2013L18.8008 20.8828Z"
					fill="url(#paint1_linear_938_13629)"
				/>
				<path
					d="M17.1172 12.7656C12.6414 12.7656 9 16.407 9 20.8828C9 25.3586 12.6414 29 17.1172 29C21.593 29 25.2344 25.3586 25.2344 20.8828C25.2344 16.407 21.593 12.7656 17.1172 12.7656ZM20.0419 21.2971L17.5315 23.8075C17.3027 24.0364 16.9317 24.0364 16.7029 23.8075L14.1924 21.2971C13.9636 21.0683 13.9636 20.6973 14.1924 20.4685L16.7029 17.958C16.9316 17.7292 17.3027 17.7292 17.5315 17.958L20.0419 20.4685C20.2707 20.6973 20.2707 21.0683 20.0419 21.2971Z"
					fill="url(#paint2_linear_938_13629)"
				/>
			</g>
			<rect
				x="0.5"
				y="0.5"
				width={37}
				height={37}
				rx="18.5"
				stroke="url(#paint3_linear_938_13629)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_938_13629"
					x1={29}
					y1="9.00035"
					x2="14.6459"
					y2="9.00035"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_938_13629"
					x1="17.1193"
					y1="19.2013"
					x2="15.4378"
					y2="20.8828"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_938_13629"
					x1="25.2344"
					y1="12.7656"
					x2={9}
					y2="12.7656"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_938_13629"
					x1={0}
					y1={38}
					x2={38}
					y2={38}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<clipPath id="clip0_938_13629">
					<rect
						width={20}
						height={20}
						fill="white"
						transform="translate(29 29) rotate(180)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
