import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { DecentralizedWalletClient } from 'skychain-decentralized-wallet-js';
import { IMasterWallet } from 'skychain-decentralized-wallet-js/lib/schema';
import { useEffect, useState } from 'react';
import CustomTable from '../UI/CustomTable';
import { Button, Switch, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import AddNewWalletModal from './AddNewWalletModal';
import { format } from 'date-fns';
import UpdateStatusModal from './UpdateStatusWalletModal';
import { setIsLoading } from '../../redux/reducers/system.slice';

interface DataType {
	address: string;
	createdAt: string;
	updatedAt: string;
	id: string;
	isActive: boolean;
	network: string;
	symbols: string;
}
const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 30px;
`;

const HotWallets = () => {
	const decentralizedWallet = useSelector(
		(state: RootState) => state.system.decentralizedWallet
	);
	const dispatch = useAppDispatch();
	const [api, contextHolder] = notification.useNotification();
	const [wallets, setWallets] = useState<IMasterWallet[]>([]);
	const [selectedWalletData, setSelectedWalletData] = useState<{
		id: string;
		isActive: boolean;
	}>();
	const [openUpdateStatusWalletModal, setOpenUpdateStatusWalletModal] =
		useState<boolean>(false);
	const [openAddNewWalletModal, setOpenAddNewWalletModal] =
		useState<boolean>(false);
	const columns: ColumnsType<DataType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Network',
			dataIndex: 'network',
			key: 'network',
		},
		{
			title: 'Symbol',
			dataIndex: 'symbols',
			key: 'symbols',
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string) => (
				<div>{format(new Date(text), 'yyyy-MM-dd HH:mm a')}</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (text: boolean) => (
				<p style={{ color: text ? 'green' : '#cf304a' }}>
					{text ? 'Active' : 'Inactive'}
				</p>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (text: string, record: DataType) => (
				<Switch
					checked={record.isActive}
					onClick={() => {
						setSelectedWalletData({
							id: record.id,
							isActive: record.isActive,
						});
						setOpenUpdateStatusWalletModal(true);
					}}
				/>
			),
		},
	];

	useEffect(() => {
		getAllWallets(decentralizedWallet);
	}, [decentralizedWallet]);

	const getAllWallets = async (
		decentralizedWallet: DecentralizedWalletClient | null
	) => {
		if (!decentralizedWallet) return;
		dispatch(setIsLoading(true));
		try {
			const res = await decentralizedWallet.getAllMasterWallet();
			setWallets(res);
			dispatch(setIsLoading(false));
		} catch (error) {
			console.log('getAllWallets', error);
			dispatch(setIsLoading(false));
		}
	};

	const updateStatusWallet = async () => {
		if (!selectedWalletData || !decentralizedWallet) return;
		try {
			const res = await decentralizedWallet.updateStatusMasterWallet({
				id: selectedWalletData?.id,
				isActive: !selectedWalletData?.isActive,
			});
			if (res?.id) {
				api.success({
					message: 'Update status successfully',
				});
				getAllWallets(decentralizedWallet);
				setOpenUpdateStatusWalletModal(false);
			}
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Update status failed',
			});
			console.log('updateStatusWallet', error);
		}
	};
	return (
		<div>
			<Header>
				<Button
					ghost
					type="primary"
					onClick={() => setOpenAddNewWalletModal(true)}
				>
					<PlusOutlined /> Add New Wallet
				</Button>
			</Header>

			<CustomTable columns={columns} dataSource={wallets} />
			<UpdateStatusModal
				type="wallet"
				open={openUpdateStatusWalletModal}
				onClose={() => setOpenUpdateStatusWalletModal(false)}
				onSubmit={updateStatusWallet}
				isActive={selectedWalletData?.isActive}
			/>
			<AddNewWalletModal
				open={openAddNewWalletModal}
				onClose={() => setOpenAddNewWalletModal(false)}
				refresh={() => getAllWallets(decentralizedWallet)}
			/>
			{contextHolder}
		</div>
	);
};

export default HotWallets;
