import React from 'react';
import CustomInput from '../../UI/CustomInput';
import CustomSelect from '../../UI/CustomSelect';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';
import { getUnixTime } from 'date-fns';
import { TxStatusOptions } from '../../../constants';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		gap: 0;
		width: 100%;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

interface Props {
	setQuery: (query: TransactionQueryRequest) => void;
	params: any;
	setParams: (params: any) => void;
}

const TransactionFilter: React.FC<Props> = ({
	setQuery,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			setParams({ ...params, page: 1 });
			const fromDate = getUnixTime(values.date && new Date(values.date[0]));
			const toDate = getUnixTime(values.date && new Date(values.date[1]));
			setQuery({ ...values, fromDate, toDate });
		}, 500);
	};
	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomInput
						label="Token"
						name={'assetSymbol'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
							placeholder: 'Enter token symbol',
						}}
					/>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/>
					<CustomSelect
						label="Status"
						name={'state'}
						options={TxStatusOptions}
						selectProps={{
							allowClear: true,
							placeholder: 'Select Tx Status',
							style: { width: 200 },
							onChange: () => {
								form.submit();
							},
						}}
					/>
					{/* <CustomCheckbox>Only Super Admin</CustomCheckbox> */}
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default TransactionFilter;
