import { Button, Form } from 'antd';
import {
	GameCenterGameUserQuery,
	PageOptions,
} from 'skychain-game-center-js/lib/dto';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';

interface Props {
	setQuery: (query: GameCenterGameUserQuery) => void;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
`;
const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
`;

const Filter = ({ setQuery, params, setParams }: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);

		filterTimeout = setTimeout(() => {
			setParams({ ...params, page: 1 });
			setQuery(values);
		}, 500);
	};

	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<FilterWrapper>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
				</FilterWrapper>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default Filter;
