import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import CustomRangePicker from '../UI/CustomRangePicker';
import { ReportingQueryDto } from 'skychain-reporting-js/lib/dto';
import { endOfDay, format, startOfDay } from 'date-fns';
import { PAST_WEEK } from '../GameManagement/Orders';
import dayjs from 'dayjs';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
`;

interface Props {
	query: ReportingQueryDto;
	setQuery: (query: ReportingQueryDto) => void;
}

interface FormValues {
	email: string;
	date: any;
}

const ReportFilter = ({ query, setQuery }: Props) => {
	const [form] = Form.useForm<FormValues>();
	let filterTimeout: any;

	const onFinish = (values: FormValues) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			const fromDate =
				values.date && values.date[0]
					? format(startOfDay(new Date(values.date[0])), 'yyyy-MM-dd') + ''
					: format(new Date(PAST_WEEK), 'yyyy-MM-dd');
			const toDate =
				values.date && values.date[1]
					? format(endOfDay(new Date(values.date[1])), 'yyyy-MM-dd') + ''
					: format(new Date(), 'yyyy-MM-dd');
			setQuery({
				...query,
				params: [`%${values.email || ''}%`, fromDate, toDate],
			});
		}, 500);
	};

	useEffect(() => {
		form.setFieldsValue({
			date: [
				dayjs(new Date(query.params[1]), 'YYYY/MM/DD'),
				dayjs(new Date(query.params[2]), 'YYYY/MM/DD'),
			],
		});
	}, [query]);

	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</Filter>
			</Form>
		</Wrapper>
	);
};

export default ReportFilter;
