import { DefaultOptionType } from 'antd/es/select';
import { endOfWeek, getUnixTime, startOfWeek } from 'date-fns';

export const KRYPTO8_ADMIN = 'KRYPTO8_ADMIN';
export const TxStatusOptions: DefaultOptionType[] = [
	{
		label: 'Init',
		value: 'INIT',
	},
	{
		label: 'Validating',
		value: 'VALIDATING',
	},
	{
		label: 'Invalid',
		value: 'INVALID',
	},
	{
		label: 'Waiting for approval',
		value: 'WAITING_FOR_APPROVAL',
	},
	{
		label: 'Processing',
		value: 'PROCESSING',
	},
	{
		label: 'Completed',
		value: 'COMPLETED',
	},
	{
		label: 'Failed',
		value: 'FAILED',
	},
	{
		label: 'Rejecting',
		value: 'REJECTING',
	},
	{
		label: 'Rejected',
		value: 'REJECTED',
	},
	{
		label: 'Approved',
		value: 'APPROVED',
	},
];

export const START_OF_WEEK = getUnixTime(startOfWeek(new Date()));
export const END_OF_WEEK = getUnixTime(endOfWeek(new Date()));

export const TWO_YEARS_AGO = getUnixTime(
	new Date(Date.now() - 730 * 24 * 60 * 60 * 1000)
);
