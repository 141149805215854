import React from 'react';

export default function FranchiseIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.6688 6.87501L16.1688 2.5C16.1137 2.40463 16.0344 2.32549 15.939 2.27062C15.8435 2.21574 15.7352 2.18707 15.625 2.1875H4.37505C4.26491 2.18707 4.15662 2.21574 4.06114 2.27062C3.96565 2.32549 3.88636 2.40463 3.8313 2.5L1.3313 6.87501C1.27633 6.9702 1.24745 7.07821 1.24756 7.18813C1.24767 7.29805 1.27676 7.406 1.33191 7.50108C1.38706 7.59617 1.46632 7.67503 1.56167 7.7297C1.65703 7.78438 1.76512 7.81294 1.87505 7.8125H2.81255V15.9375C2.81255 16.4348 3.01009 16.9117 3.36172 17.2633C3.71335 17.615 4.19026 17.8125 4.68755 17.8125H15.3125C15.8098 17.8125 16.2867 17.615 16.6384 17.2633C16.99 16.9117 17.1875 16.4348 17.1875 15.9375V7.8125H18.125C18.235 7.81294 18.3431 7.78438 18.4384 7.7297C18.5338 7.67503 18.613 7.59617 18.6682 7.50108C18.7233 7.406 18.7524 7.29805 18.7525 7.18813C18.7526 7.07821 18.7238 6.9702 18.6688 6.87501ZM13.75 13.75C13.75 13.9158 13.6842 14.0747 13.567 14.1919C13.4498 14.3092 13.2908 14.375 13.125 14.375H6.87505C6.70929 14.375 6.55031 14.3092 6.4331 14.1919C6.31589 14.0747 6.25005 13.9158 6.25005 13.75V10.9375C6.25005 10.7717 6.31589 10.6128 6.4331 10.4956C6.55031 10.3784 6.70929 10.3125 6.87505 10.3125H13.125C13.2908 10.3125 13.4498 10.3784 13.567 10.4956C13.6842 10.6128 13.75 10.7717 13.75 10.9375V13.75Z"
				fill="#737283"
			/>
		</svg>
	);
}
