import React from 'react';

export default function GameManagementIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.542 11.4976C18.542 11.318 18.5292 11.1416 18.5068 10.9682L20 10.1518L18.5764 7.54804L17.0834 8.36433C16.7419 8.07652 16.3526 7.84398 15.9293 7.67933V5.97839H12.9618V7.67937C12.5385 7.84402 12.1492 8.07656 11.8077 8.36437L10.3147 7.54808L8.89111 10.1518L10.3843 10.9682C10.3619 11.1416 10.3491 11.318 10.3491 11.4976C10.3491 11.6771 10.3619 11.8535 10.3843 12.027L8.89111 12.8434L10.3147 15.4471L11.8077 14.6308C12.1492 14.9186 12.5385 15.1511 12.9618 15.3158V17.0168H15.9293V15.3158C16.3526 15.1511 16.7419 14.9186 17.0834 14.6308L18.5764 15.4471L20 12.8434L18.5068 12.027C18.5292 11.8535 18.542 11.6771 18.542 11.4976ZM14.4455 12.7581C13.7494 12.7581 13.185 12.1937 13.185 11.4976C13.185 10.8014 13.7494 10.2371 14.4455 10.2371C15.1417 10.2371 15.706 10.8014 15.706 11.4976C15.706 12.1937 15.1417 12.7581 14.4455 12.7581Z"
				fill="#737283"
			/>
			<path
				d="M11.7274 6.98487C11.7482 6.97233 11.769 6.95999 11.79 6.94772V4.80651H14.7145V4.52753C14.7145 3.6746 14.0231 2.98315 13.1702 2.98315H1.54437C0.691445 2.98319 0 3.67464 0 4.52757V11.1145C0 12.0295 0.741719 12.7712 1.65664 12.7712C2.17961 12.7712 2.67184 12.5242 2.98453 12.1051L4.63215 9.8964H7.69516L9.84859 5.95769L11.7274 6.98487ZM4.65352 6.84128H3.85809V7.63671H2.68621V6.84128H1.89074V5.6694H2.68617V4.87397H3.85805V5.6694H4.65352V6.84128ZM7.13023 7.95538H5.95836V6.78351H7.13023V7.95538Z"
				fill="#737283"
			/>
		</svg>
	);
}
