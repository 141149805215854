import { Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CustomSelect from '../UI/CustomSelect';
import {
	ExecuteOrderStatus,
	ExecuteOrderType,
	QueryExecuteOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import { convertStatusToCapitalized } from '../../utils';
import CustomInput from '../UI/CustomInput';

const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const StatusOptions = Object.values(ExecuteOrderStatus).map((status) => {
	return {
		label: convertStatusToCapitalized(status.replace('exorder_', '')),
		value: status,
	};
});

const TypeOptions = Object.values(ExecuteOrderType).map((type) => {
	return {
		label: convertStatusToCapitalized(type.replace('exorder_', '')),
		value: type,
	};
});

interface PropsType {
	handleFilter: (query: QueryExecuteOrder) => void;
}

export default function Filter({ handleFilter }: PropsType) {
	const [form] = Form.useForm();
	const onFinish = (values: any) => {
		console.log(values);
	};

	const handleFieldsChange = () => {
		const query = form.getFieldsValue() as QueryExecuteOrder;
		if (!query.assetSymbol) {
			delete query['assetSymbol'];
		}
		if (!query.types) {
			delete query['types'];
		}
		if (!query.statuses) {
			delete query['statuses'];
		}
		handleFilter(query);
	};

	// useEffect(() => {}, []);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			// initialValues={{
			// 	assetSymbol: CryptoOptions[0].value,
			// 	types: TypeOptions[0].value,
			// 	statuses: StatusOptions[0].value,
			// }}
			onFieldsChange={handleFieldsChange}
		>
			<FilterWrapper>
				<CustomInput
					label="Token"
					name={'assetSymbol'}
					inputProps={{
						onKeyDown: (e) => {
							if (e.key === 'Enter') {
								form.submit();
							}
						},
						onChange: () => {
							form.submit();
						},
						allowClear: true,
					}}
				/>
				<CustomSelect
					label="Type"
					options={TypeOptions}
					// defaultValue={TypeOptions[0].value}
					name="types"
					selectProps={{
						size: 'large',
						allowClear: true,
						style: {
							width: 200,
						},
					}}
				/>

				<CustomSelect
					label="Status"
					options={StatusOptions}
					// defaultValue={StatusOptions[0].value}
					name="statuses"
					selectProps={{
						size: 'large',
						allowClear: true,
						style: {
							width: 200,
						},
					}}
				/>
			</FilterWrapper>
		</Form>
	);
}
