import BigNumber from 'bignumber.js';

export const getEnv = (
	variable: string,
	defaultValue?: number | string
): any => {
	return process.env[variable] || defaultValue;
};

export const shortenAddress = (address: string): string => {
	return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const shortenEmail = (text: string): string => {
	const splited = text.split('@');
	const emailName = splited[0] || '';
	const emailExtension = splited[1] || '';
	if (emailName.length >= 10) {
		return `${emailName.slice(0, 3)}***${emailName.slice(
			-2
		)}@${emailExtension}`;
	}
	return text;
};

export const convertStatusToCapitalized = (status: string): string => {
	if (!status) return '';
	const newText = status.split('_').join(' ');
	return newText.charAt(0).toUpperCase() + newText.slice(1).toLowerCase();
};

export const formatCurrency = (
	value: number | string | undefined,
	decimal?: number
) => {
	if (!value) return '0';
	const result = new BigNumber(value).toNumber();
	return new Intl.NumberFormat('en', {
		maximumFractionDigits: decimal || 2,
	}).format(result);
};
