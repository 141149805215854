import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../routes/config';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import { Drawer } from 'antd';
import { MenuItem, menus } from './SideBar';
import LogoutIcon from '../../components/Icons/LogoutIcon';
import { KRYPTO8_ADMIN } from '../../constants';

const Wrapper = styled('div')`
	background: rgba(9, 18, 41, 0.5);
	padding: 20px 50px;
	display: flex;
	align-items: center;
	gap: 20px;
	@media screen and (max-width: 576px) {
		padding: 20px;
	}
`;

const LogoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
`;

const MobileMenu = styled('div')`
	display: none;
	@media screen and (max-width: 576px) {
		display: block;
	}
`;
export default function NavTop() {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleSignOut = () => {
		localStorage.removeItem(KRYPTO8_ADMIN);
		navigate(ROUTES.LOGIN);
	};

	return (
		<Wrapper>
			<MobileMenu onClick={showDrawer}>
				<MenuOutlined />
			</MobileMenu>
			<LogoWrapper onClick={() => navigate(ROUTES.HOME)}>
				<img src="/images/logo.svg" alt="logo" />
				<img src="/images/admin-tag.svg" alt="admin tag" />
			</LogoWrapper>
			<Drawer
				title={
					<LogoWrapper
						onClick={() => {
							navigate(ROUTES.HOME);
							setOpen(false);
						}}
					>
						<img src="/images/logo.svg" alt="logo" />
						<img src="/images/admin-tag.svg" alt="admin tag" />
					</LogoWrapper>
				}
				placement="left"
				onClose={onClose}
				open={open}
			>
				{menus.map((menuItem) => (
					<MenuItem
						key={menuItem.label}
						label={menuItem.label}
						icon={menuItem.icon}
						active={location.pathname.includes(menuItem.link)}
						onClick={() => {
							setOpen(false);
							navigate(menuItem.link);
						}}
					/>
				))}
				<MenuItem
					label={'Logout'}
					icon={<LogoutIcon />}
					onClick={handleSignOut}
				/>
			</Drawer>
		</Wrapper>
	);
}
