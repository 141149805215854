import { Button, Form } from 'antd';
import CustomInput from '../UI/CustomInput';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import MoreIcon from '../Icons/MoreIcon';
import Copy from '../UI/Copy';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useEffect, useState } from 'react';
import { UserIdentityAdminClient } from 'user-identity-js';
import { PageOptions, UserQuery } from 'user-identity-js/lib/dto';
import { format } from 'date-fns';
import { UserStatus } from 'user-identity-js/lib/entities/user.entity';
import UpdateUserStatusModal from './UpdateUserStatusModal';
import { convertStatusToCapitalized } from '../../utils';
import { setIsLoading } from '../../redux/reducers/system.slice';
import { date_format } from '../../config';
const Wrapper = styled('div')``;

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const Header = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ActionWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 15px;
`;

export const ActionButtonWrapper = styled('button')`
	cursor: pointer;
	line-height: 0px;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;

export const StatusText = styled('p')<{ status?: UserStatus }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		`${
			props.status === UserStatus.ACTIVED
				? '#03a66d'
				: props.status === UserStatus.BANNED
				? '#CF304A'
				: '#fff'
		}`};
`;

export const DisplayNameWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #1c84e4;
	}
`;

interface DataType {
	userId: string;
	email: string;
	createdAt: string;
	status: string;
}

const UserList = () => {
	const [form] = Form.useForm();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [users, setUsers] = useState<DataType[]>([]);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [total, setTotal] = useState(0);
	const [userQuery, setUserQuery] = useState<UserQuery>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<DataType>();
	const dispatch = useAppDispatch();
	let filterTimeout: any;

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	useEffect(() => {
		getAllUsers(userIdentity, userQuery, params);
	}, [userIdentity, params, userQuery]);

	const getAllUsers = async (
		userIdentity: UserIdentityAdminClient | null,
		userQuery: UserQuery | undefined,
		params: PageOptions
	) => {
		if (!userIdentity) return;
		dispatch(setIsLoading(true));
		try {
			const res = await userIdentity.getAllUser(userQuery, params);
			setUsers(
				res.data.map((item) => {
					return {
						...item,
						userId: item.id || '',
						email: item.email || '',
						createdAt: item.createdAt
							? format(new Date(item.createdAt * 1000), date_format)
							: '',
						status: item.status || '',
					};
				})
			);
			dispatch(setIsLoading(false));
			setTotal(res.meta.totalRecord);
		} catch (error) {
			console.log('getAllUsers', error);
			dispatch(setIsLoading(false));
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};
	const onFinish = (values: UserQuery) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			setParams({
				...params,
				page: 1,
			});
			setUserQuery(values);
		}, 500);
	};
	const columns: ColumnsType<DataType> = [
		{
			title: 'User ID',
			dataIndex: 'userId',
			key: 'userId',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<Copy text={text} />
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Created Date',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (text) => (
				<StatusText status={text}>
					{convertStatusToCapitalized(text)}
				</StatusText>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<ActionWrapper>
					{/* <ActionButtonWrapper>
						<EditIcon />
					</ActionButtonWrapper> */}
					<ActionButtonWrapper
						onClick={() => {
							setIsModalOpen(true);
							setSelectedUser(record);
						}}
					>
						<MoreIcon />
					</ActionButtonWrapper>
				</ActionWrapper>
			),
		},
	];
	return (
		<Wrapper>
			<Header>
				<Form form={form} onFinish={onFinish} layout="vertical">
					<Filter>
						<CustomInput
							style={{ width: 200 }}
							label="Email"
							name={'email'}
							inputProps={{
								placeholder: 'Enter your email address',
								onKeyDown: (e) => {
									if (e.key === 'Enter') {
										form.submit();
									}
								},
								onChange: () => {
									form.submit();
								},
								allowClear: true,
							}}
						/>
					</Filter>
				</Form>
				{/* <Button type="primary" ghost>
					Export Excel
				</Button> */}
			</Header>
			<CustomTable
				columns={columns}
				dataSource={users}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				current={params.page}
				total={total}
			/>
			<UpdateUserStatusModal
				open={isModalOpen}
				onClose={handleCancel}
				defaultValue={selectedUser?.status as UserStatus}
				userId={selectedUser?.userId as string}
				refresh={() => getAllUsers(userIdentity, userQuery, params)}
			/>
		</Wrapper>
	);
};

export default UserList;
