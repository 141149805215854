import React from 'react';

export default function GameTrialIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_879_1584)">
				<path
					d="M20 14.2102C19.9531 11.6008 19.6449 8.96216 19.1394 6.29888C18.7277 4.44849 17.2464 2.80044 14.8796 2.67974C13.1324 2.60786 12.7945 3.60318 10.8148 3.58404C10.2726 3.58052 9.73081 3.58052 9.18863 3.58404C7.20855 3.60318 6.86988 2.60786 5.12339 2.67974C2.75621 2.80044 1.22886 4.44419 0.862065 6.29888C0.356206 8.96216 0.0480026 11.6004 0.00151824 14.2098C-0.00980989 16.0266 1.78394 17.2352 2.96753 17.318C5.25386 17.4907 7.07027 13.459 8.45425 13.4586C9.48589 13.4645 10.5171 13.4649 11.5488 13.4586C12.9332 13.4586 14.748 17.4911 17.0359 17.3184C18.2191 17.2356 20.0593 16.0188 20.0003 14.2102H20ZM7.45855 9.1188H6.39214V10.1852C6.39214 10.6352 6.0273 11.0001 5.5773 11.0001C5.1273 11.0001 4.76246 10.6352 4.76246 10.1852V9.1188H3.69605C3.24605 9.1188 2.88121 8.75396 2.88121 8.30396C2.88121 7.85396 3.24605 7.48911 3.69605 7.48911H4.76246V6.42271C4.76246 5.97271 5.1273 5.60786 5.5773 5.60786C6.0273 5.60786 6.39214 5.97271 6.39214 6.42271V7.48911H7.45855C7.90855 7.48911 8.27339 7.85396 8.27339 8.30396C8.27339 8.75396 7.90855 9.1188 7.45855 9.1188ZM14.0433 10.9997C13.4054 11.0168 12.875 10.5129 12.8578 9.87544C12.841 9.2356 13.3453 8.70435 13.9828 8.68794C14.621 8.67193 15.1523 9.17544 15.1687 9.81372C15.1847 10.452 14.6812 10.9833 14.0433 10.9997ZM15.9671 7.91958C15.3293 7.93755 14.7976 7.43326 14.78 6.79536C14.764 6.1563 15.2675 5.62583 15.9058 5.60826C16.5449 5.59146 17.0753 6.09576 17.0925 6.73404C17.1093 7.37193 16.6046 7.90357 15.9671 7.91958Z"
					fill="#737283"
				/>
			</g>
			<defs>
				<clipPath id="clip0_879_1584">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
