import { Button, Form } from 'antd';
import { getUnixTime } from 'date-fns';
import { debounce } from 'lodash';
import React from 'react';
import { IRefCommissionListQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import styled from 'styled-components';
import CustomInput from '../../UI/CustomInput';
import CustomRangePicker from '../../UI/CustomRangePicker';
import { PAST_WEEK } from '../../GameManagement/Orders';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import { END_OF_WEEK, TWO_YEARS_AGO } from '../../../constants';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

interface Props {
	setQuery: (query: IRefCommissionListQuery) => void;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}

const CommissionHistoryFilter: React.FC<Props> = ({
	setQuery,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();

	const onFinish = (values: any) => {
		const fromTime = values.date
			? getUnixTime(new Date(values.date[0]))
			: TWO_YEARS_AGO;
		const toTime =
			values.date?.length > 1
				? getUnixTime(new Date(values.date[1]))
				: END_OF_WEEK;
		setParams({ ...params, page: 1 });
		setQuery({ ...values, fromTime, toTime });
	};

	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<Filter>
					<CustomInput
						label="Email"
						name={'email'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: debounce(() => {
								form.submit();
							}, 500),
							allowClear: true,
							placeholder: '',
						}}
					/>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							// defaultValue: [
							// 	format(new Date(PAST_WEEK), date_format),
							// 	format(new Date(), date_format),
							// ],
							onChange: debounce(() => {
								form.submit();
							}, 500),
						}}
					/>
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default CommissionHistoryFilter;
