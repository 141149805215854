import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameProfileAdminClient } from 'skychain-game-profile-js/lib/admin/game-profile-admin-client';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import {
	IRefCommissionListQuery,
	REF_TRANSACTION_TYPES,
} from 'skychain-game-profile-js/lib/dto/ref.query';
import styled from 'styled-components';
import { date_format } from '../../../config';
import { setIsLoading } from '../../../redux/reducers/system.slice';
import { RootState, useAppDispatch } from '../../../redux/store';
import { formatCurrency } from '../../../utils';
import Copy from '../../UI/Copy';
import CustomTable from '../../UI/CustomTable';

const CopyWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 5px;
	p {
		color: #1c84e4;
		font-size: 12px;
		line-height: 16.1px;
	}
`;

interface DataType {
	userEmail: string;
	date: string;
	amount: string;
	commissionRate: string;
	causeAmount: string;
	type: string;
}

interface CommissionHistoryTableProps {
	query: IRefCommissionListQuery;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}

const TypeText = styled('p')<any>`
	background: ${(props) =>
		props.type === REF_TRANSACTION_TYPES.CASHBACK
			? 'linear-gradient(138.96deg, #f7931e 1.86%, #fbb03b 65.6%)'
			: props.type === REF_TRANSACTION_TYPES.SAMELEVEL
			? 'linear-gradient(270deg, #8B6BE6 -30.6%, #E2589A 134.33%)'
			: 'linear-gradient(270deg, #78BD44 -34.33%, #29A961 135.68%)'};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-size: 12px;
	line-height: 16.1px;
	text-transform: capitalize;
`;

const columns: ColumnsType<DataType> = [
	{
		title: 'User',
		dataIndex: 'userEmail',
		key: 'userEmail',
		render: (value) => {
			return (
				<CopyWrapper>
					<p>{value}</p>
					{value && <Copy text={value} />}
				</CopyWrapper>
			);
		},
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Commission Rate',
		dataIndex: 'commissionRate',
		key: 'commissionRate',
	},
	{
		title: 'Cause Amount',
		dataIndex: 'causeAmount',
		key: 'causeAmount',
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		render: (value: string) => {
			return (
				<TypeText type={value}>
					{value.replaceAll('_', ' ').toLowerCase()}
				</TypeText>
			);
		},
	},
];
const CommissionHistoryTable: React.FC<CommissionHistoryTableProps> = ({
	query,
	params,
	setParams,
}: CommissionHistoryTableProps) => {
	const gameProfile = useSelector(
		(state: RootState) => state.system.gameProfile
	);
	const dispatch = useAppDispatch();
	const [commissions, setCommissions] = React.useState<DataType[]>([]);

	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (gameProfile) {
			getCommissionHistory(gameProfile, query, params);
		}
	}, [gameProfile, params, query]);

	const getCommissionHistory = async (
		gameProfile: GameProfileAdminClient,
		query: IRefCommissionListQuery,
		params: PageOptions
	) => {
		dispatch(setIsLoading(true));
		try {
			const res = await gameProfile.getRefCommissionList(query, params);
			if (res.data) {
				const data: DataType[] = res.data.map((item) => {
					const note: any = item.note;
					return {
						userEmail: item.email || '',
						date: format(Number(item.createdAt) * 1000, date_format),
						amount: `$${formatCurrency(item.amount, 3)}`,
						commissionRate: `${Number(note?.commissionRate || 0) * 100}%`,
						causeAmount: `$${formatCurrency(item.causeAmount, 3)}`,
						type: item.type || '',
					};
				});
				setCommissions(data);
			}

			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('error', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<CustomTable
			columns={columns}
			dataSource={commissions}
			onChange={onChangeTable}
			pageSize={params.pageSize}
			total={total}
			current={params.page}
		/>
	);
};

export default CommissionHistoryTable;
