import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIdentityAdminClient } from 'user-identity-js';
import styled from 'styled-components';
import { ActionButtonWrapper, DisplayNameWrapper } from '../../UserList';
import Copy from '../../UI/Copy';
import { RootState, useAppDispatch } from '../../../redux/store';
import { shortenAddress, convertStatusToCapitalized } from '../../../utils';
import CustomTable from '../../UI/CustomTable';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	GAME_EXPERIENCE_REGISTER_STATUS,
	GameExperienceUserReport,
	IAuditGameExperience,
	QueryGameExRegisterDto,
} from 'skychain-game-center-js/lib/dto/game-experience.dto';
import { format } from 'date-fns';
import { Button } from 'antd';
import { setIsLoading } from '../../../redux/reducers/system.slice';
import { PageOptions } from 'skychain-game-center-js/lib/dto';

// const StyledModal = styled(Modal)`
// 	.ant-modal-content {
// 		background: transparent !important;
// 	}
// 	.ant-modal-close {
// 		color: #ffffff !important;
// 	}
// `;
const Status = styled.div<{ status: GAME_EXPERIENCE_REGISTER_STATUS }>(
	(props) => `
     color: ${
				props.status === GAME_EXPERIENCE_REGISTER_STATUS.VALID
					? 'green'
					: props.status === GAME_EXPERIENCE_REGISTER_STATUS.INVALID
					? '#cf304a'
					: props.status === GAME_EXPERIENCE_REGISTER_STATUS.PENDING
					? '#FFA500'
					: '#737283'
			}
`
);
const Action = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

interface Props {
	query?: QueryGameExRegisterDto;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const GameTrialRegisteredTable: React.FC<Props> = ({
	query,
	params,
	setParams,
}: Props) => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useAppDispatch();
	const [gameExs, setGameExs] = useState<GameExperienceUserReport[]>([]);

	const [total, setTotal] = useState(0);
	// const [imageUrl, setImageUrl] = useState<string>('');
	// const [zoomImg, setZoomImg] = useState<boolean>(false);

	const columns: ColumnsType<GameExperienceUserReport> = [
		{
			title: 'User ID',
			dataIndex: 'userId',
			key: 'userId',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{shortenAddress(text)}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Time Shift',
			dataIndex: 'allTimeShift',
			key: 'allTimeShift',
		},

		{
			title: 'Register Time',
			dataIndex: 'dateQuery',
			key: 'dateQuery',
			render: (text) => (
				<p>{format(new Date(text * 1000), 'yyyy-MM-dd HH:mm')}</p>
			),
		},
		{
			title: 'Package',
			dataIndex: 'numPackage',
			key: 'numPackage',
			render: (text) => <p>{text}</p>,
		},
		{
			title: 'Game Trial Package',
			dataIndex: 'allAmount',
			key: 'allAmount',
			render: (text) => <p>{text}</p>,
		},
		// {
		// 	title: 'USDT Refund',
		// 	dataIndex: 'cashbackAmount',
		// 	key: 'cashbackAmount',
		// },

		{
			title: 'Status',
			key: 'allStatus',
			dataIndex: 'allStatus',
			render: (text) => (
				<Status status={text}>{convertStatusToCapitalized(text)}</Status>
			),
		},
		{
			title: 'Action',
			key: 'transaction_hash',
			render: (_, record) => (
				<>
					{record.allStatus
						?.split(',')
						.find(
							(item: string) =>
								item.trim() === GAME_EXPERIENCE_REGISTER_STATUS.REQUESTED
						) && (
						<Action>
							<Button
								style={{
									border: '1px solid #00FF00',
									color: '#00FF00',
								}}
								type="primary"
								ghost
								onClick={() => {
									const index = record.allStatus
										?.split(',')
										.findIndex(
											(item: string) =>
												item.trim() ===
												GAME_EXPERIENCE_REGISTER_STATUS.REQUESTED
										);
									auditGameTrial({
										id: record.allIds.split(',')[index]?.trim(),
										status: GAME_EXPERIENCE_REGISTER_STATUS.APPROVED,
									});
								}}
							>
								Approve
							</Button>
							<Button
								style={{
									border: '1px solid #FF0000',
									color: '#FF0000',
								}}
								type="primary"
								ghost
								onClick={() => {
									const index = record.allStatus
										?.split(',')
										.findIndex(
											(item: string) =>
												item.trim() ===
												GAME_EXPERIENCE_REGISTER_STATUS.REQUESTED
										);
									auditGameTrial({
										id: record.allIds.split(',')[index]?.trim(),
										status: GAME_EXPERIENCE_REGISTER_STATUS.REJECTED,
									});
								}}
							>
								Reject
							</Button>
						</Action>
					)}
				</>
			),
		},
	];

	useEffect(() => {
		getRegisterGameTrial(gameCenterAdminClient, userIdentity, query, params);
	}, [gameCenterAdminClient, userIdentity, params, query]);

	const auditGameTrial = async (data: IAuditGameExperience) => {
		if (!gameCenterAdminClient) return;
		dispatch(setIsLoading(true));
		try {
			await gameCenterAdminClient.auditGameEx(data);
			getRegisterGameTrial(gameCenterAdminClient, userIdentity, query, params);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('auditGameTrial', error);
		}
	};

	const getRegisterGameTrial = async (
		gameCenterAdminClient: GameCenterAdminClient | null,
		userIdentity: UserIdentityAdminClient | null,
		query?: QueryGameExRegisterDto,
		params?: any
	) => {
		if (!gameCenterAdminClient || !userIdentity) return;
		dispatch(setIsLoading(true));
		try {
			const res = await gameCenterAdminClient.getGameExperienceList(
				query,
				params
			);

			setGameExs(res.data);
			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('getTransactions', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<>
			<CustomTable
				columns={columns}
				dataSource={gameExs}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
				current={params.page}
			/>
			{/* <StyledModal
				footer={null}
				open={zoomImg}
				onCancel={() => setZoomImg(false)}
			>
				<img src={imageUrl} />
			</StyledModal> */}
		</>
	);
};

export default GameTrialRegisteredTable;
