import { Button, Form } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import styled from 'styled-components';
import DetailIcon from '../Icons/DetailIcon';
import CustomTable from '../UI/CustomTable';
import TitlePage from '../UI/TitlePage';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useEffect, useState } from 'react';
import { UserIdentityAdminClient } from 'user-identity-js';
import { PageOptions } from 'user-identity-js/lib/dto';
import { KYCState } from 'user-identity-js/lib/entities/kyc.entity';
import { format } from 'date-fns';
import CustomSelect from '../UI/CustomSelect';
import { QueryKYCDto } from 'user-identity-js/lib/dto/user-kyc-dto';
import { DefaultOptionType } from 'antd/es/select';
import { convertStatusToCapitalized } from '../../utils';
import { setIsLoading } from '../../redux/reducers/system.slice';
import { date_format } from '../../config';

const Wrapper = styled('div')``;

const Title = styled('p')`
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	margin: 0px;
`;

const Filter = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
`;

const Header = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 30px;
`;

export const ActionWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	color: #737283;
	cursor: pointer;
`;

export const ActionButtonWrapper = styled('button')`
	cursor: pointer;
	line-height: 0px;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;

enum STATUS {
	PENDING = 'pending',
	REJECT = 'reject',
	APPROVE = 'approve',
}

const StatusText = styled('p')<{ status?: string }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) =>
		`${
			props.status === KYCState.VERIFIED
				? '#03a66d'
				: props.status === KYCState.REJECTED
				? '#CF304A'
				: props.status === KYCState.INIT
				? '#fff'
				: 'yellow'
		}`};
	${(props) =>
		props.status === STATUS.PENDING &&
		`background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;`}
`;

export const EmailWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #1c84e4;
	}
`;

interface DataType {
	email: string;
	displayName: string;
	createdAt: string;
	status: string;
	id: string;
}

const Options: DefaultOptionType[] = Object.values(KYCState).map((item) => ({
	label: convertStatusToCapitalized(item),
	value: item,
}));

export default function KYC() {
	const navigate = useNavigate();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();
	const [data, setData] = useState<DataType[]>([]);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [query, setQuery] = useState<QueryKYCDto>();
	const [total, setTotal] = useState(0);

	const columns: ColumnsType<DataType> = [
		{
			title: 'Display Name',
			dataIndex: 'displayName',
			key: 'displayName',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => (
				<EmailWrapper>
					<p>{text}</p>
				</EmailWrapper>
			),
		},
		{
			title: 'Created Date',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (text: string) => (
				<StatusText status={text}>
					{convertStatusToCapitalized(text)}
				</StatusText>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<ActionWrapper
					onClick={() => {
						navigate(`${ROUTES.KYC}/${record['id']}`);
					}}
				>
					<p>Detail</p>
					<ActionButtonWrapper>
						<DetailIcon />
					</ActionButtonWrapper>
				</ActionWrapper>
			),
		},
	];

	useEffect(() => {
		if (userIdentity) {
			getKycList(userIdentity, query, params);
		}
	}, [userIdentity, params, query]);

	const getKycList = async (
		userIdentity: UserIdentityAdminClient,
		query: QueryKYCDto | undefined,
		params: PageOptions
	) => {
		dispatch(setIsLoading(true));
		try {
			const res = await userIdentity.getAllKYC(query, params);

			const ids = res.data
				.map((item) => item.identifier)
				.filter((id) => id) as string[];
			const users = await userIdentity.getUsers({ ids });
			if (users) {
				const newData = res.data.map((item) => ({
					id: item.id || '',
					displayName: item.firstName + ' ' + item.lastName,
					email:
						users.data.find((user) => user.id === item.identifier)?.email || '',
					status: item.state,
					createdAt: item.createdAt
						? format(new Date(item.createdAt * 1000), date_format)
						: '',
				}));
				setData(newData);
			}
			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('getKycList', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};

	const onFinish = (values: QueryKYCDto) => {
		setParams({ ...params, page: 1 });
		setQuery(values);
	};
	return (
		<Wrapper>
			<TitlePage title={<Title>KYC</Title>} />
			<Header>
				<Form
					style={{ width: '100%' }}
					form={form}
					onFinish={onFinish}
					layout="vertical"
				>
					<Filter>
						<CustomSelect
							noStyle
							options={Options}
							name={'state'}
							selectProps={{
								placeholder: 'Select KYC status',
								size: 'large',
								allowClear: true,
								onChange: () => {
									form.submit();
								},
							}}
							style={{ width: 200 }}
						/>
						{/* <Button type="primary" ghost>
							Export Excel
						</Button> */}
					</Filter>
				</Form>
			</Header>

			<CustomTable
				columns={columns}
				dataSource={data}
				total={total}
				pageSize={params.pageSize}
				onChange={onChangeTable}
				current={params.page}
			/>
		</Wrapper>
	);
}
