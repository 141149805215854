import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
`;

export const GeneralInfo = styled.div`
	position: absolute;
	top: -8px;
	right: 0;
	display: flex;
	gap: 30px;
	align-items: center;
	flex-wrap: wrap;
`;

export const GeneralInfoItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
export const GeneralInfoText = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
`;
export const TotalOrders = styled(GeneralInfoText)`
	color: #0092c0;
`;

export const UserParticipation = styled(GeneralInfoText)`
	color: #03a66d;
`;

export const TotalVolume = styled(GeneralInfoText)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;
