import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import CustomTable from '../../UI/CustomTable';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	GameCenterGameUserQuery,
	GameCenterGameUser,
} from 'skychain-game-center-js/lib/dto';
import { PageOptions } from 'user-identity-js/lib/dto';
import { RootState, useAppDispatch } from '../../../redux/store';
import styled from 'styled-components';
import Filter from './Filter';
import Copy from '../../UI/Copy';
import { formatCurrency } from '../../../utils';
import { setIsLoading } from '../../../redux/reducers/system.slice';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;
const DisplayNameWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #1c84e4;
	}
`;
const ActionButtonWrapper = styled('button')`
	cursor: pointer;
	line-height: 0px;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;
const GameUsers = () => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const dispatch = useAppDispatch();
	const [query, setQuery] = useState<GameCenterGameUserQuery>();
	const [gameUsers, setGameUsers] = useState<GameCenterGameUser[]>([]);
	const [total, setTotal] = useState<number>(0);
	const columns: ColumnType<GameCenterGameUser>[] = [
		{
			title: 'Code',
			dataIndex: 'userCode',
			key: 'userCode',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Game Balance',
			dataIndex: 'gameBalance',
			render: (text) => <div>${formatCurrency(text)}</div>,
		},
		{
			title: 'Net Profit/Loss',
			dataIndex: 'profit',
			render: (text) => (
				<div
					style={{
						color: text > 0 ? 'green' : text < 0 ? 'red' : '#737283',
					}}
				>
					{formatCurrency(text)}
				</div>
			),
		},
		{
			title: 'Bet Amount',
			dataIndex: 'betAmount',
			render: (text) => <div>{formatCurrency(text)}</div>,
		},
		{
			title: 'Withdraw',
			dataIndex: 'totalWithdrawAmount',
			render: (text) => <div>${formatCurrency(text)}</div>,
		},
		{
			title: 'Deposit',
			dataIndex: 'totalDepositAmount',
			render: (text) => <div>${formatCurrency(text)}</div>,
		},
	];

	useEffect(() => {
		getGameUsers(gameCenterAdminClient, query, params);
	}, [gameCenterAdminClient, params, query]);

	const getGameUsers = async (
		gameCenterAdminClient: GameCenterAdminClient,
		query?: GameCenterGameUserQuery,
		params?: PageOptions
	) => {
		if (!gameCenterAdminClient) return;
		try {
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.getGameCenterGameUsers(
				query,
				params
			);
			setTotal(res.meta.totalRecord || 0);
			setGameUsers(res.data);
		} catch (error) {
			console.log('getSuppliers', error);
		} finally {
			dispatch(setIsLoading(false));
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		window.scrollTo(0, 0);
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};
	return (
		<Wrapper>
			<Filter setQuery={setQuery} params={params} setParams={setParams} />
			<CustomTable
				columns={columns}
				dataSource={gameUsers}
				pageSize={params.pageSize}
				total={total}
				onChange={onChangeTable}
				current={params.page}
			/>
		</Wrapper>
	);
};

export default GameUsers;
