import { Button, Form, Modal, notification } from 'antd';
import styled from 'styled-components';
import PrimaryButton from '../UI/PrimaryButton';
import CustomInput from '../UI/CustomInput';
import CustomSelect from '../UI/CustomSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import CustomCheckbox from '../UI/CustomCheckbox';
import BigNumber from 'bignumber.js';
import { DefaultOptionType } from 'antd/es/select';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		background: #070c1e;
		border-radius: 8px;
		padding: 0;
		color: #737283;
		width: 700px;
		@media screen and (max-width: 576px) {
			width: 100%;
		}
	}
	.ant-modal-header {
		background: #091229;
		height: 60px;
		padding: 20px 30px;
	}
	.ant-modal-title {
		color: #d1d4dc;
	}
	.ant-modal-body {
		padding: 10px 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
`;
const Footer = styled.div`
	display: flex;
	gap: 30px;
	align-items: center;
	margin-top: 30px;
	justify-content: center;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
	@media screen and (max-width: 576px) {
		gap: 10px;
	}
`;
const Label = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
	width: 150px;
	@media screen and (max-width: 576px) {
		width: 100%;
	}
`;

const FormContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding-bottom: 30px;
`;
interface Props {
	open: boolean;
	onClose: () => void;
	refresh: () => void;
}

const currencyOptions: DefaultOptionType[] = [
	{
		value: 'USDT',
		label: 'USDT',
	},
];
const CreateUserCurrencyConfigModal: React.FC<Props> = ({
	open,
	onClose,
	refresh,
}: Props) => {
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const onFinish = async (values: any) => {
		if (!metaverseJs) return;
		try {
			const body: Asset = {
				...values,
				depositStatus: values.depositStatus ? 'ENABLED' : 'DISABLED',
				withdrawStatus: values.withdrawStatus ? 'ENABLED' : 'DISABLED',
				flatFee: new BigNumber(values.flatFee).toNumber(),
				transactionFeePercent: new BigNumber(
					values.transactionFeePercent
				).toNumber(),
				minPerOneOrder: new BigNumber(values.minPerOneOrder).toNumber(),
				maxPerOneDay: new BigNumber(values.maxPerOneDay).toNumber(),
				thresholdToReview: new BigNumber(values.thresholdToReview).toNumber(),
				status: 'ENABLED',
			};
			const res = await metaverseJs.createAccountCurrencyConfig(body);
			if (res.id) {
				api.success({
					message: 'Create new user config successfully',
				});
				handleClose();
				refresh();
			}
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};

	const handleClose = () => {
		onClose();
		form.resetFields();
	};

	return (
		<StyledModal
			title="Create New User Currency Config"
			open={open}
			onCancel={handleClose}
			footer={null}
			centered
		>
			{contextHolder}
			<Form onFinish={onFinish} form={form}>
				<FormContent>
					<Row>
						<Label>UserId</Label>
						<Row style={{ flex: 1 }}>
							<CustomInput
								style={{
									width: '100%',
									margin: 0,
								}}
								name={'userId'}
								rules={[
									{
										required: true,
										message: 'This field is required',
									},
								]}
							/>
						</Row>
					</Row>
					<Row>
						<Label>Select Enable</Label>
						<Row style={{ flex: 1 }}>
							<CustomCheckbox
								style={{
									marginBottom: 0,
								}}
								name={'depositStatus'}
								label="Deposit"
							/>
							<CustomCheckbox
								style={{
									marginBottom: 0,
								}}
								name={'withdrawStatus'}
								label="Withdraw"
							/>
						</Row>
					</Row>
					<Row>
						<Label>Currency</Label>
						<Row style={{ flex: 1 }}>
							<CustomSelect
								style={{
									width: '100%',
									margin: 0,
								}}
								name={'symbol'}
								label=""
								options={currencyOptions}
								selectProps={{
									style: {
										width: '100%',
									},
								}}
								rules={[
									{
										required: true,
										message: 'This field is required',
									},
								]}
								initialValue={currencyOptions[0].value}
							/>
						</Row>
					</Row>

					<Row>
						<Label>Withdraw amount</Label>
						<Row style={{ flex: 1 }}>
							<CustomInput
								labelCol={{ span: 24 }}
								label="Min per one order"
								name={'minPerOneOrder'}
								inputProps={{
									type: 'number',
								}}
							/>
							<CustomInput
								labelCol={{ span: 24 }}
								label="Threshod to review"
								name={'thresholdToReview'}
								inputProps={{
									type: 'number',
								}}
							/>

							<CustomInput
								labelCol={{ span: 24 }}
								label="Max per one day"
								name={'maxPerOneDay'}
								inputProps={{
									type: 'number',
								}}
							/>
						</Row>
					</Row>
					<Row>
						<Label>Platform fee (*)</Label>
						<div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
							<Row style={{ flex: 1 }}>
								<CustomInput
									labelCol={{ span: 24 }}
									name={'flatFee'}
									label="Flat fee"
									inputProps={{
										type: 'number',
									}}
								/>
								<CustomInput
									labelCol={{ span: 24 }}
									name={'transactionFeePercent'}
									label="Transaction fee pecent (%)"
									inputProps={{
										type: 'number',
									}}
								/>
							</Row>
							<div style={{ color: '#ffffff40', width: '100%' }}>
								Platform fee = Flat fee + Transaction fee = Flat fee + (Withdraw
								amount * Transaction fee pecent)
							</div>
						</div>
					</Row>

					<Footer>
						<Button
							type="primary"
							ghost
							style={{
								width: '40%',
								height: 46,
							}}
							onClick={handleClose}
						>
							Cancel
						</Button>
						<PrimaryButton
							style={{
								width: '40%',
							}}
							htmlType="submit"
						>
							Save
						</PrimaryButton>
					</Footer>
				</FormContent>
			</Form>
		</StyledModal>
	);
};

export default CreateUserCurrencyConfigModal;
