export default function TotalValidPackageIcon(props: any) {
	return (
		<svg
			{...props}
			width={56}
			height={55}
			viewBox="0 0 56 55"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_2464_3140)">
				<g filter="url(#filter1_d_2464_3140)">
					<path
						d="M39.222 29.2301C39.222 30.4705 39.0526 31.6719 38.7372 32.8135C41.3299 30.6528 42.9829 27.4009 42.9829 23.7699C42.9829 17.28 37.7029 12 31.213 12C27.582 12 24.3302 13.653 22.1695 16.2457C23.3109 15.9303 24.5124 15.7609 25.7528 15.7609C33.1797 15.7609 39.222 21.8032 39.222 29.2301Z"
						fill="white"
					/>
					<path
						d="M28.1941 29.2295L25.7559 31.6677L23.3177 29.2295L25.7559 26.7913L28.1941 29.2295Z"
						fill="white"
					/>
					<path
						d="M25.7528 17.4602C19.2628 17.4602 13.9829 22.7401 13.9829 29.2301C13.9829 35.72 19.2628 41 25.7528 41C32.2427 41 37.5227 35.72 37.5227 29.2301C37.5227 22.7401 32.2427 17.4602 25.7528 17.4602ZM29.9936 29.8308L26.3535 33.4709C26.0218 33.8027 25.4838 33.8027 25.152 33.4709L21.5119 29.8308C21.1801 29.499 21.1801 28.9611 21.5119 28.6293L25.152 24.9892C25.4837 24.6574 26.0217 24.6574 26.3535 24.9892L29.9936 28.6293C30.3254 28.9611 30.3254 29.499 29.9936 29.8308Z"
						fill="white"
					/>
				</g>
				<rect
					x="2.49097"
					y="0.5"
					width="51.984"
					height={52}
					rx="25.992"
					stroke="white"
					shapeRendering="crispEdges"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_2464_3140"
					x="0.990967"
					y={0}
					width="54.9839"
					height={55}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation="0.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2464_3140"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2464_3140"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_d_2464_3140"
					x="12.9829"
					y={12}
					width={31}
					height={31}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation="0.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2464_3140"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2464_3140"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
