import styled from 'styled-components';
import Copy from '../UI/Copy';
import TitlePage from '../UI/TitlePage';
import { Col, Row, notification } from 'antd';
import ExpandIcon from '../Icons/ExpandIcon';
import useModal from '../../hooks/useModal';
import ExpandModal from './ExpandModal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { UserIdentityAdminClient } from 'user-identity-js';
import {
	KYCState,
	UserKYCEntity,
} from 'user-identity-js/lib/entities/kyc.entity';
import { UserEntity } from 'user-identity-js/lib/entities';
import { ROUTES } from '../../routes/config';
import LayoutDetailPage from '../../layouts/LayoutDetailPage';
import { setIsLoading } from '../../redux/reducers/system.slice';

const Wrapper = styled('div')`
	p {
		margin: 0;
	}
`;

const TitleWtapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	p {
		color: #ffffff;
		margin: 0px;
	}
	span {
		color: #1c84e4;
		margin: 0px;
	}
`;

const BodyWrapper = styled('div')``;
const SectionWrapper = styled('div')`
	width: 100%;
	max-width: 590px;
	margin-bottom: 20px;
`;
const SectionTitleWrapper = styled('div')`
	margin-bottom: 28px;
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 28px;
		color: #ffffff;
		margin: 0;
	}
`;
const BodySectionWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 15px;
`;
const Label = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
`;
const Content = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
`;
const ImageWrapper = styled('div')`
	position: relative;
	width: 100%;
	height: max-content;
	img {
		width: 100%;
	}
`;
const ExpandButtonWrapper = styled('button')`
	cursor: pointer;
	position: absolute;
	bottom: 15px;
	right: 10px;
	line-height: 0px;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;

const RejectButton = styled('button')`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	background: #484756;
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #ffffff;
	cursor: pointer;
`;

const ApproveButton = styled('button')`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #181a20;
	cursor: pointer;
`;
const key = 'KYC';
interface UserKyc extends UserKYCEntity {
	user: UserEntity;
}
export default function KycDetail() {
	const params = useParams();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const id = params.id;
	const [user, setUser] = useState<UserKyc>();
	const { isOpen: isExpand, toggle: toggleExpand } = useModal();
	const [srcExpand, setSrcExpand] = useState('');
	const dispatch = useAppDispatch();

	const handleExpand = (src: string) => {
		setSrcExpand(src);
		toggleExpand();
	};

	const handleReject = async () => {
		await verifyKyc(id as string, KYCState.REJECTED);
	};
	const handleApprove = async () => {
		await verifyKyc(id as string, KYCState.VERIFIED);
	};
	useEffect(() => {
		if (userIdentity && id) {
			getKycById(userIdentity, id);
		}
	}, [userIdentity, id]);

	const getKycById = async (
		userIdentity: UserIdentityAdminClient,
		id: string
	) => {
		dispatch(setIsLoading(true));
		try {
			const res = await userIdentity.getKYCByID(id);
			const user = await userIdentity.getUser(res.data.identifier);
			setUser({
				...res.data,
				user,
			});
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('getKycById', error);
		}
	};

	const verifyKyc = async (id: string, state: KYCState) => {
		if (!userIdentity || !id) return;
		try {
			const res = await userIdentity.verifyKYC(id, { state });
			if (res) {
				api['success']({
					key,
					message:
						state === KYCState.VERIFIED
							? 'Verify KYC successfully'
							: 'Reject KYC successfully',
				});
				getNextKyc();
			}
		} catch (error: any) {
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		}
	};

	const getNextKyc = async () => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.getNextKycSubmitted();
			if (res) {
				navigate(ROUTES.KYC_DETAIL.replace(':id', res.id || ''));
			} else {
				navigate(ROUTES.KYC);
			}
		} catch (error) {
			console.log('getNextKyc', error);
		}
	};

	return (
		<LayoutDetailPage>
			<Wrapper>
				<TitlePage
					title={
						<TitleWtapper>
							<p>
								{user?.firstName + ' ' + user?.lastName}:{' '}
								<span>{user?.user?.email}</span>
							</p>
							<Copy text={user?.user?.email || ''} />
						</TitleWtapper>
					}
				/>
				<BodyWrapper>
					<SectionWrapper>
						<SectionTitleWrapper>
							<p>1. Basic information</p>
						</SectionTitleWrapper>

						<BodySectionWrapper>
							<Row gutter={20}>
								<Col span={10}>
									<Label>Country or Region:</Label>
								</Col>
								<Col span={14}>
									<Content>{user?.countryOrRegion}</Content>
								</Col>
							</Row>
							<Row gutter={20}>
								<Col span={10}>
									<Label>Certified ID Type:</Label>
								</Col>
								<Col span={14}>
									<Content>
										{user?.idCertificateType} - {user?.idNumber}
									</Content>
								</Col>
							</Row>
							<Row gutter={20}>
								<Col span={10}>
									<Label>First Name:</Label>
								</Col>
								<Col span={14}>
									<Content>{user?.firstName}</Content>
								</Col>
							</Row>
							<Row gutter={20}>
								<Col span={10}>
									<Label>Last Name:</Label>
								</Col>
								<Col span={14}>
									<Content>{user?.lastName}</Content>
								</Col>
							</Row>
						</BodySectionWrapper>
					</SectionWrapper>

					<SectionWrapper>
						<SectionTitleWrapper>
							<p>2. ID Photo</p>
						</SectionTitleWrapper>

						<BodySectionWrapper>
							<Row gutter={20}>
								<Col span={10}>
									<Label>Front cover of the ID Document:</Label>
								</Col>
								<Col span={14}>
									<ImageWrapper>
										<img
											src={user?.idDocumentFront}
											alt="front-cover-id-document"
										/>
										<ExpandButtonWrapper
											onClick={() => {
												handleExpand(user?.idDocumentFront || '');
											}}
										>
											<ExpandIcon />
										</ExpandButtonWrapper>
									</ImageWrapper>
								</Col>
							</Row>
							<Row gutter={20}>
								<Col span={10}>
									<Label>Personal information page:</Label>
								</Col>
								<Col span={14}>
									<ImageWrapper>
										<img src={user?.idDocumentBack} alt="personal-info" />
										<ExpandButtonWrapper
											onClick={() => {
												handleExpand(user?.idDocumentBack || '');
											}}
										>
											<ExpandIcon />
										</ExpandButtonWrapper>
									</ImageWrapper>
								</Col>
							</Row>
							<Row gutter={20}>
								<Col span={10}>
									<Label>
										The hand-held documents & personal signature photos:
									</Label>
								</Col>
								<Col span={14}>
									<ImageWrapper>
										<img
											src={user?.idDocumentSelfie}
											alt="hand-held-document"
										/>
										<ExpandButtonWrapper
											onClick={() => {
												handleExpand(user?.idDocumentSelfie || '');
											}}
										>
											<ExpandIcon />
										</ExpandButtonWrapper>
									</ImageWrapper>
								</Col>
							</Row>
						</BodySectionWrapper>
					</SectionWrapper>
					{user?.state === KYCState.SUBMITTED && (
						<SectionWrapper>
							<Row gutter={20}>
								<Col span={10}>
									<RejectButton onClick={handleReject}>Reject</RejectButton>
								</Col>
								<Col span={14}>
									<ApproveButton onClick={handleApprove}>Approve</ApproveButton>
								</Col>
							</Row>
						</SectionWrapper>
					)}
				</BodyWrapper>

				<ExpandModal isOpen={isExpand} toggle={toggleExpand} src={srcExpand} />
				{contextHolder}
			</Wrapper>
		</LayoutDetailPage>
	);
}
