import React, { useState } from 'react';
import WithdrawFilter from './Filter';
import WithdrawTable from './Table';
import {
	QueryTransaction,
	TransactionState,
	TransactionType,
} from 'skychain-game-center-js/lib/dto/transaction.dto';
import { PageOptions } from 'skychain-game-center-js/lib/dto';
const GameTransactions = () => {
	const [query, setQuery] = useState<QueryTransaction>({
		txnType: TransactionType.WITHDRAW,
		orders: ['createdAt desc'],
		state: TransactionState.WAITING_FOR_APPROVAL,
	});
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});

	return (
		<div>
			<WithdrawFilter
				query={query}
				setQuery={setQuery}
				params={params}
				setParams={setParams}
			/>
			<WithdrawTable query={query} params={params} setParams={setParams} />
		</div>
	);
};

export default GameTransactions;
