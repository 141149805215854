import React from 'react';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import CustomRangePicker from '../../UI/CustomRangePicker';
import {
	GAME_EXPERIENCE_REGISTER_STATUS,
	QueryGameExRegisterDto,
} from 'skychain-game-center-js/lib/dto/game-experience.dto';
import CustomSelect from '../../UI/CustomSelect';
import { convertStatusToCapitalized } from '../../../utils';
import { PageOptions } from 'user-identity-js/lib/dto';

const FilterWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;
const Actions = styled('div')`
	display: flex;
	gap: 20px;
	align-items: center;
`;

const Status = [
	...Object.values(GAME_EXPERIENCE_REGISTER_STATUS).map((item) => ({
		value: item,
		label: convertStatusToCapitalized(item),
	})),
];
interface Props {
	setQuery: (query: QueryGameExRegisterDto) => void;
	query?: QueryGameExRegisterDto;
	params?: PageOptions;
	setParams: (params: PageOptions) => void;
}

const Filter: React.FC<Props> = ({
	setQuery,
	query,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			const newQuery = { ...query };
			if (values.status === '') {
				delete newQuery.status;
			} else {
				newQuery.status = values.status;
			}
			const fromDate =
				values.date && values.date[0]
					? getUnixTime(startOfDay(new Date(values.date[0]))) + ''
					: '';
			const toDate =
				values.date && values.date[1]
					? getUnixTime(endOfDay(new Date(values.date[1]))) + ''
					: '';
			setParams({ ...params, page: 1 });
			setQuery({ ...newQuery, fromDate, toDate });
		}, 500);
	};
	return (
		<Wrapper>
			<Form form={form} onFinish={onFinish} layout="vertical">
				<FilterWrapper>
					<CustomRangePicker
						name="date"
						label="Time"
						dateProps={{
							onChange: () => {
								form.submit();
							},
						}}
					/>
					<CustomSelect
						options={Status}
						label="Status"
						name={'status'}
						selectProps={{
							placeholder: 'Select Status',
							style: { width: 200 },
							allowClear: true,
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</FilterWrapper>
			</Form>
			{/* <Actions>
				<Button
					style={{
						width: 140,
					}}
					type="primary"
					ghost
				>
					Export Excel
				</Button>
			</Actions> */}
		</Wrapper>
	);
};

export default Filter;
