export const config = {
	base_url: process.env.REACT_APP_BASE_URL || '',
	bscsan_url:
		process.env.REACT_APP_ENV === 'development'
			? 'https://testnet.bscscan.com/'
			: 'https://bscscan.com/',
};

export const date_format = 'yyyy-MM-dd hh:mm a';
export const date_range_format = 'YYYY-MM-DD';

export const SCAN_URL_CONFIG: {
	[key: string]: string;
} = {
	BSC: (process.env.REACT_APP_BSC_SCAN || '') + 'tx/',
	ETH: (process.env.REACT_APP_ETH_SCAN || '') + 'tx/',
	TRON: (process.env.REACT_APP_TRON_SCAN || '') + '#/transaction/',
};
