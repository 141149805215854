import { useState } from 'react';
import FranchiseTableFilter from './Filter';
import FranchiseTable from './Table';
import { IRefSummaryQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import styled from 'styled-components';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';

const Wrapper = styled('div')`
	margin-top: 10px;
`;

export default function FranchiseTableTab() {
	const [query, setQuery] = useState<IRefSummaryQuery>({});
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
		// order: PageOrder.DESC,
	});
	return (
		<Wrapper>
			<FranchiseTableFilter
				setParams={setParams}
				params={params}
				setQuery={setQuery}
			/>
			<FranchiseTable params={params} query={query} setParams={setParams} />
		</Wrapper>
	);
}
