import React from 'react';
import CustomSelect from '../UI/CustomSelect';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import { getUnixTime } from 'date-fns';
import {
	QueryTransaction,
	TransactionState,
	TransactionType,
} from 'skychain-game-center-js/lib/dto/transaction.dto';
import CustomInput from '../UI/CustomInput';
import { convertStatusToCapitalized } from '../../utils';
import { PageOptions } from 'skychain-game-center-js/lib/dto';

const Filter = styled('div')`
	display: flex;
	align-items: center;
	gap: 20px;
	color: #737283;
	flex-wrap: wrap;
	@media screen and (max-width: 576px) {
		width: 100%;
		gap: 0;
	}
`;

const Wrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

const TxStatusOptions = Object.values(TransactionState).map((item) => ({
	label: convertStatusToCapitalized(item),
	value: item,
}));

const TxTypes = Object.values(TransactionType).map((item) => ({
	label: convertStatusToCapitalized(item),
	value: item,
}));
interface Props {
	setQuery: (query: QueryTransaction) => void;
	query: QueryTransaction;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const WithdrawProgressFilter: React.FC<Props> = ({
	setQuery,
	query,
	params,
	setParams,
}: Props) => {
	const [form] = Form.useForm();
	let filterTimeout: any;

	const onFinish = (values: any) => {
		clearTimeout(filterTimeout);

		filterTimeout = setTimeout(() => {
			const fromDate = getUnixTime(values.date && new Date(values.date[0]));
			const toDate = getUnixTime(values.date && new Date(values.date[1]));
			setParams({ ...params, page: 1 });
			setQuery({
				...query,
				...values,
				fromDate,
				toDate,
				txnId: values.txnId?.trim(),
				userCode: values.userCode?.trim(),
			});
		}, 500);
	};
	return (
		<Wrapper>
			<Form onFinish={onFinish} form={form} layout="vertical">
				<Filter>
					<CustomInput
						label="Transaction ID"
						name={'txnId'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
					<CustomInput
						label="User code"
						name={'userCode'}
						inputProps={{
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									form.submit();
								}
							},
							onChange: () => {
								form.submit();
							},
							allowClear: true,
						}}
					/>
					<CustomSelect
						label="Transaction Type"
						name={'txnType'}
						options={TxTypes}
						defaultValue={TransactionType.WITHDRAW}
						selectProps={{
							allowClear: true,
							placeholder: 'Select Tx Status',
							style: { width: 200 },
							onChange: () => {
								form.submit();
							},
						}}
					/>
					<CustomSelect
						label="Status"
						name={'state'}
						options={TxStatusOptions}
						defaultValue={TransactionState.WAITING_FOR_APPROVAL}
						selectProps={{
							allowClear: true,
							placeholder: 'Select Tx Status',
							style: { width: 200 },
							onChange: () => {
								form.submit();
							},
						}}
					/>
				</Filter>
			</Form>
			{/* <Button type="primary" ghost>
				Export Excel
			</Button> */}
		</Wrapper>
	);
};

export default WithdrawProgressFilter;
