import { createBrowserRouter, redirect } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import HomePage from '../pages/home';
import { ROUTES } from './config';
import LoginPage from '../pages/login';
import { KRYPTO8_ADMIN } from '../constants';
import Users from '../pages/users';
import KYCPage from '../pages/kyc';
import KycDetailPage from '../pages/kyc-detail';
import AdManagementPage from '../pages/ad-management';
import AdManagementDetail from '../components/AdManagement/Detail';
import BuySellCryptoPage from '../pages/buy-sell-crypto';
import BuySellCryptoDetail from '../components/BuySellCrypto/Detail';
import UserReportPage from '../pages/reports/user';
import HotWalletsPage from '../pages/hot-wallets';
import GameTrialPackagePage from '../pages/game-trial-package';
import GameShowPage from '../pages/game-show';
import LayoutTab from '../layouts/LayoutTab';
import {
	WALLET_TABS,
	CURRENCY_CONFIG_TABS,
	FRANCHISE_TABS,
	REPORT_TABS,
} from '../constants/tabs';
import TransactionDepositPage from '../pages/transactions/deposit';
import TransactionWithdrawPage from '../pages/transactions/withdraw';
import CurrencyConfigPage from '../pages/wallets/currency';
import UserCurrencyConfigPage from '../pages/wallets/user-currency-config';
import GameUsersPage from '../pages/game-management/game-users';
import OrdersPage from '../pages/game-management/orders';
import SuppliersPage from '../pages/game-management/suppliers';
import GamesPage from '../pages/game-management/games';
import CommisionHistoryPage from '../pages/franchise/commision-history';
import AuditCommisionPage from '../pages/franchise/audit-commision';
import FranchisePage from '../pages/franchise';
import AuditCommisionDetailPage from '../pages/franchise/audit-commision-detail';
import GameManagementLayout from '../layouts/GameMagementLayout';
import GameTransactionsPage from '../pages/game-transactions';
import GameExperienceReportPage from '../pages/reports/game-experience';

export const router = createBrowserRouter([
	{
		element: <MainLayout />,
		loader: async () => {
			const user = localStorage.getItem(KRYPTO8_ADMIN);
			const jsonUser = user ? JSON.parse(user) : null;
			if (!jsonUser) {
				return redirect(ROUTES.LOGIN);
			}
			return jsonUser;
		},
		children: [
			{
				path: ROUTES.HOME,
				element: <HomePage />,
			},
			{
				path: ROUTES.USER_LIST,
				element: <Users />,
			},
			{
				path: ROUTES.HOT_WALLETS,
				element: <HotWalletsPage />,
			},
			{
				path: ROUTES.TRANSACTIONS,
				element: <LayoutTab tabs={WALLET_TABS} />,
				children: [
					{
						path: ROUTES.TRANSACTION_DEPOSIT,
						element: <TransactionDepositPage />,
					},
					{
						path: ROUTES.TRANSACTION_WITHDRAW,
						element: <TransactionWithdrawPage />,
					},
				],
			},
			{
				path: ROUTES.KYC,
				element: <KYCPage />,
			},
			{
				path: ROUTES.KYC_DETAIL,
				element: <KycDetailPage />,
			},
			{
				path: ROUTES.AD_MANAGEMENT,
				element: <AdManagementPage />,
			},
			{
				path: ROUTES.AD_MANAGEMENT_DETAIL,
				element: <AdManagementDetail />,
			},
			{
				path: ROUTES.BUY_SELL_CRYPTO,
				element: <BuySellCryptoPage />,
			},
			{
				path: ROUTES.BUY_SELL_CRYPTO_DETAIL,
				element: <BuySellCryptoDetail />,
			},
			{
				path: ROUTES.REPORTS.INDEX,
				element: <LayoutTab tabs={REPORT_TABS} />,
				children: [
					{
						path: ROUTES.REPORTS.USER_REPORT,
						element: <UserReportPage />,
					},
					{
						path: ROUTES.REPORTS.GAME_TRIAL_REPORT,
						element: <GameExperienceReportPage />,
					},
				],
			},

			{
				path: ROUTES.WALLET_CONFIG,
				element: <LayoutTab tabs={CURRENCY_CONFIG_TABS} />,
				children: [
					{
						path: ROUTES.WALLET_CONFIG_CURRENCY,
						element: <CurrencyConfigPage />,
					},
					{
						path: ROUTES.WALLET_CONFIG_USER_CURRENCY_CONFIG,
						element: <UserCurrencyConfigPage />,
					},
				],
			},
			{
				path: ROUTES.GAME_TRIAL_PACKAGE,
				element: <GameTrialPackagePage />,
			},
			{
				path: ROUTES.GAME_SHOW,
				element: <GameShowPage />,
			},
			{
				path: ROUTES.GAME_MANAGEMENT,
				element: <GameManagementLayout />,
				children: [
					{
						path: ROUTES.GAME_MANAGEMENT_USERS,
						element: <GameUsersPage />,
					},
					{
						path: ROUTES.GAME_MANAGEMENT_ORDERS,
						element: <OrdersPage />,
					},
					{
						path: ROUTES.GAME_MANAGEMENT_SUPPLIERS,
						element: <SuppliersPage />,
					},
					{
						path: ROUTES.GAME_MANAGEMENT_GAMES,
						element: <GamesPage />,
					},
				],
			},
			{
				path: ROUTES.GAME_TRANSACTIONS,
				element: <GameTransactionsPage />,
			},
			{
				path: ROUTES.FRANCHISE,
				element: <LayoutTab tabs={FRANCHISE_TABS} />,
				children: [
					{
						path: ROUTES.FRANCHISE_TABLE,
						element: <FranchisePage />,
					},
					{
						path: ROUTES.FRANCHISE_COMMISION_HISTORY,
						element: <CommisionHistoryPage />,
					},
					{
						path: ROUTES.FRANCHISE_AUDIT_COMMISION,
						element: <AuditCommisionPage />,
					},
					{
						path: ROUTES.FRANCHISE_AUDIT_COMMISION_DETAIL,
						element: <AuditCommisionDetailPage />,
					},
				],
			},
		],
	},
	{
		path: ROUTES.LOGIN,
		element: <LoginPage />,
	},
]);
