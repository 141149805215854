import { Modal, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 8px;
		background: #070c1e;
		padding: 0;
		overflow: hidden;
	}
	.ant-modal-close {
		color: #555361 !important;
	}
`;

const UpdateWrapper = styled('div')`
	cursor: pointer;
	p {
		color: #1c84e4;
		font-size: 12px;
		line-height: 16.1px;
	}
`;

const TitleWrapper = styled('div')`
	background: #091229;
	padding: 20px 30px;
`;

const Title = styled('p')`
	color: #d1d4dc;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.2px;
	margin: 0;
`;

const BodyWrapper = styled('div')`
	padding: 20px 30px;
`;

const RadioGroup = styled(Radio.Group)`
	display: flex;
	align-items: center;
	gap: 25px;
	label {
		margin-right: 0;
	}
`;

const LevelImg = styled('img')`
	width: 30px;
	height: 30px;
`;

const ActionWrapper = styled('div')`
	padding: 20px 30px;
`;

const UpdateButton = styled('button')`
	display: flex;
	width: 100%;
	height: 50px;
	padding: 12px 140px;
	justify-content: center;
	align-items: center;
	gap: 20px;
	flex-shrink: 0;
	border-radius: 4px;
	background: var(
		--yellow-grt,
		linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%)
	);
	cursor: pointer;
	border: none;
	outline: none;

	color: #181a20;
	font-size: 18px;
	font-weight: 500;
`;

const levels = Array.from(Array(7).keys());

export default function UpdateLevelModal({
	id,
	currentLevel,
	handleUpdateLevel,
}: {
	id: string;
	currentLevel: number | string;
	handleUpdateLevel: (id: string, level: number) => void;
}) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [value, setValue] = useState(Number(currentLevel || 0));

	const onChange = (e: RadioChangeEvent) => {
		setValue(e.target.value);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		handleUpdateLevel(id, Number(value));
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (isModalOpen) {
			setValue(Number(currentLevel || 0));
		}
	}, [isModalOpen]);

	return (
		<>
			<UpdateWrapper onClick={showModal}>
				<p>Update</p>
			</UpdateWrapper>
			<StyledModal
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				width={'max-content'}
			>
				<TitleWrapper>
					<Title>Update Level</Title>
				</TitleWrapper>
				<BodyWrapper>
					<RadioGroup onChange={onChange} value={value}>
						{levels.map((level) => {
							return (
								<Radio
									value={level}
									key={level}
									disabled={level < Number(currentLevel)}
								>
									<LevelImg
										src={`/images/vip-level/level-${level}.svg`}
										alt={`level ${level}`}
										style={{
											opacity: level < Number(currentLevel) ? 0.2 : 1.0,
										}}
									/>
								</Radio>
							);
						})}
					</RadioGroup>
				</BodyWrapper>
				<ActionWrapper>
					<UpdateButton onClick={handleOk}>Update</UpdateButton>
				</ActionWrapper>
			</StyledModal>
		</>
	);
}
