import { ROUTES } from '../routes/config';

enum TRANSACTION_TAB {
	DEPOSIT = 'Deposit',
	WITHDRAW = 'Withdraw',
}
export const WALLET_TABS: TabItem[] = [
	{
		key: TRANSACTION_TAB.DEPOSIT,
		value: 'Deposit',
		url: ROUTES.TRANSACTION_DEPOSIT,
	},
	{
		key: TRANSACTION_TAB.WITHDRAW,
		value: 'Withdraw',
		url: ROUTES.TRANSACTION_WITHDRAW,
	},
];

enum REPORT_TAB {
	USER = 'User',
	GAME_EXPERIENCE = 'Game Trial',
}
export const REPORT_TABS: TabItem[] = [
	{
		key: REPORT_TAB.USER,
		value: 'User',
		url: ROUTES.REPORTS.USER_REPORT,
	},
	{
		key: REPORT_TAB.GAME_EXPERIENCE,
		value: 'Game Trial',
		url: ROUTES.REPORTS.GAME_TRIAL_REPORT,
	},
];

enum CONFIG_TAB {
	CURRENCY_CONFIG = 'Currency',
	USER_CURRENCY_CONFIG = 'User-Currency',
}
export const CURRENCY_CONFIG_TABS: TabItem[] = [
	{
		key: CONFIG_TAB.CURRENCY_CONFIG,
		value: 'Currency Config',
		url: ROUTES.WALLET_CONFIG_CURRENCY,
	},
	{
		key: CONFIG_TAB.USER_CURRENCY_CONFIG,
		value: 'User currency config',
		url: ROUTES.WALLET_CONFIG_USER_CURRENCY_CONFIG,
	},
];

enum GAME_MANAGEMENT_TAB {
	ORDERS = 'Orders',
	SUPPLIERS = 'Suppliers',
	GAMES = 'Games',
	GAME_USERS = 'Game Users',
	DEPOSIT = 'Deposit',
	WITHDRAW = 'Withdraw',
}
export const GAME_MANAGEMENT_TABS: TabItem[] = [
	{
		key: GAME_MANAGEMENT_TAB.GAME_USERS,
		value: GAME_MANAGEMENT_TAB.GAME_USERS,
		url: ROUTES.GAME_MANAGEMENT_USERS,
	},
	{
		key: GAME_MANAGEMENT_TAB.ORDERS,
		value: GAME_MANAGEMENT_TAB.ORDERS,
		url: ROUTES.GAME_MANAGEMENT_ORDERS,
	},
	{
		key: GAME_MANAGEMENT_TAB.SUPPLIERS,
		value: GAME_MANAGEMENT_TAB.SUPPLIERS,
		url: ROUTES.GAME_MANAGEMENT_SUPPLIERS,
	},
	{
		key: GAME_MANAGEMENT_TAB.GAMES,
		value: GAME_MANAGEMENT_TAB.GAMES,
		url: ROUTES.GAME_MANAGEMENT_GAMES,
	},
];

enum FRANCHISE_TAB {
	TABLE = 'Network',
	COMMISSION_HISTORY = 'Commission History',
	AUDIT_COMMISSION = 'Audit Commission',
}

export const FRANCHISE_TABS: TabItem[] = [
	{
		key: FRANCHISE_TAB.TABLE,
		value: 'Network',
		url: ROUTES.FRANCHISE_TABLE,
	},
	{
		key: FRANCHISE_TAB.COMMISSION_HISTORY,
		value: 'Commission History',
		url: ROUTES.FRANCHISE_COMMISION_HISTORY,
	},
	{
		key: FRANCHISE_TAB.AUDIT_COMMISSION,
		value: 'Audit Commission',
		url: ROUTES.FRANCHISE_AUDIT_COMMISION,
	},
];
