import React from 'react';

export default function GameShowIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.0343 3.30174C16.7159 3.30174 17.2692 5.84929 17.2692 8.99152C17.2692 12.134 16.7161 14.6822 16.0343 14.6822C15.5274 14.6811 15.0725 13.2553 14.8861 11.0853C15.7461 10.746 16.3121 9.91596 16.3121 8.99152C16.3121 8.06796 15.7461 7.23907 14.8861 6.90107C15.0725 4.72996 15.5274 3.30307 16.0343 3.30174ZM16.1061 2.18396C15.7612 2.18396 15.5668 2.29618 15.3261 2.43152C13.4532 3.48818 10.8723 4.36063 8.49032 4.74863C7.88721 7.53307 7.98921 10.8493 8.73677 13.2751C11.0492 13.6813 13.517 14.532 15.3259 15.5517C15.5665 15.6884 15.761 15.8008 16.1059 15.8008H16.5979C17.6881 15.8008 18.5365 12.7637 18.5365 8.99174C18.5365 5.21974 17.6883 2.18396 16.5979 2.18396H16.1061Z"
				fill="#737283"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.3131 4.90125C6.80843 4.94902 6.31887 4.97325 5.85821 4.96502L3.3351 4.95813C2.05865 4.93613 0.991319 6.90836 0.94043 8.99169C0.991319 11.0748 2.05843 13.0472 3.33487 13.025L3.97954 13.023L5.1971 17.5679C5.30576 17.9732 5.61021 18.1839 6.05465 18.1839H8.69354C9.31732 18.1839 9.56621 17.7541 9.42376 17.2221L9.04976 15.8272C8.95843 15.4884 8.82888 15.3564 8.54865 15.1086L7.91554 14.5508C6.84199 10.9708 6.76354 7.79013 7.3131 4.90125Z"
				fill="#737283"
			/>
		</svg>
	);
}
