export const ROUTES = {
	ERROR: '*',
	HOME: '/',
	LOGIN: '/login',
	HOT_WALLETS: '/hot-wallets',
	USER_LIST: '/user-list',
	TRANSACTIONS: '/transactions',
	TRANSACTION_DEPOSIT: '/transactions/deposit',
	TRANSACTION_WITHDRAW: '/transactions/withdraw',
	KYC: '/kyc',
	KYC_DETAIL: '/kyc/:id',
	AD_MANAGEMENT: '/ad-management',
	AD_MANAGEMENT_DETAIL: '/ad-management/:id',
	BUY_SELL_CRYPTO: '/buy-sell-crypto',
	BUY_SELL_CRYPTO_DETAIL: '/buy-sell-crypto/:id',
	GAME_TRIAL_PACKAGE: '/game-trial-package',
	CLAIM: '/claim',
	GAME_SHOW: '/game-show',
	FRANCHISE: '/franchise',
	FRANCHISE_TABLE: '/franchise/network',
	FRANCHISE_COMMISION_HISTORY: '/franchise/commission-history',
	FRANCHISE_AUDIT_COMMISION: '/franchise/audit-commission',
	FRANCHISE_AUDIT_COMMISION_DETAIL: '/franchise/audit-commission/:id',
	GAME_MANAGEMENT: '/game-management',
	GAME_MANAGEMENT_USERS: '/game-management/users',
	GAME_MANAGEMENT_ORDERS: '/game-management/orders',
	GAME_MANAGEMENT_SUPPLIERS: '/game-management/suppliers',
	GAME_MANAGEMENT_GAMES: '/game-management/games',
	GAME_MANAGEMENT_DEPOSIT: '/game-management/deposit',
	GAME_MANAGEMENT_WITHDRAW: '/game-management/withdraw',
	GAME_TRANSACTIONS: '/game-transactions',
	REPORTS: {
		INDEX: '/reports',
		USER_REPORT: '/reports/user',
		GAME_TRIAL_REPORT: '/reports/game-trial',
	},
	CONFIG: '/config',
	WALLET_CONFIG: '/wallet-config',
	WALLET_CONFIG_CURRENCY: '/wallet-config/currency',
	WALLET_CONFIG_USER_CURRENCY_CONFIG: '/wallet-config/user-currency-config',
};
