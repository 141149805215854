import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import CustomTable from '../../UI/CustomTable';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	GameCenterGameOrderQuery,
	GameCenterGameOrder,
} from 'skychain-game-center-js/lib/dto';
import { PageOptions } from 'user-identity-js/lib/dto';
import { RootState, useAppDispatch } from '../../../redux/store';
import { format, getUnixTime } from 'date-fns';
import styled from 'styled-components';
import Filter from './Filter';
import Copy from '../../UI/Copy';
import { formatCurrency } from '../../../utils';
import { setIsLoading } from '../../../redux/reducers/system.slice';
import { END_OF_WEEK, START_OF_WEEK, TWO_YEARS_AGO } from '../../../constants';
export const PAST_WEEK = Date.now() - 7 * 24 * 60 * 60 * 1000;

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;
const DisplayNameWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #1c84e4;
	}
`;
const ActionButtonWrapper = styled('button')`
	cursor: pointer;
	line-height: 0px;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;
const Orders = () => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const dispatch = useAppDispatch();
	const [query, setQuery] = useState<GameCenterGameOrderQuery>({
		fromTime: TWO_YEARS_AGO,
		toTime: END_OF_WEEK,
	});
	const [orders, setOrders] = useState<GameCenterGameOrder[]>([]);
	const [total, setTotal] = useState<number>(0);
	const columns: ColumnType<GameCenterGameOrder>[] = [
		{
			title: 'ID',
			dataIndex: 'roundId',
			key: 'roundId',
		},
		{
			title: 'Suppliers',
			key: 'suppliers',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
					}}
				>
					<img
						src={record.vendorImage}
						style={{
							width: '70px',
							height: ' 70px',
							objectFit: 'contain',
							borderRadius: '5px',
						}}
					/>
					<div>{record.vendorName}</div>
				</div>
			),
		},

		{
			title: 'Game',
			key: 'game',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
					}}
				>
					<img
						src={record.gameImage}
						style={{
							width: '70px',
							height: ' 70px',
							objectFit: 'cover',
							borderRadius: '100%',
						}}
					/>
					<div>{record.gameName}</div>
				</div>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},

		{
			title: 'Net Profit/Loss',
			dataIndex: 'profit',
			render: (text) => (
				<div
					style={{
						color: text > 0 ? 'green' : text < 0 ? 'red' : '#737283',
					}}
				>
					{formatCurrency(text)}
				</div>
			),
		},
		{
			title: 'Bet Amount',
			dataIndex: 'betAmount',
			render: (text) => <div>{formatCurrency(text)}</div>,
		},
		{
			title: 'Played At',
			dataIndex: 'logTime',
			render: (text) => (
				<div>{format(new Date(text * 1000), 'yyyy-MM-dd HH:mm')}</div>
			),
		},
	];

	useEffect(() => {
		getOrders(gameCenterAdminClient, query, params);
	}, [gameCenterAdminClient, params, query]);

	const getOrders = async (
		gameCenterAdminClient: GameCenterAdminClient,
		query: GameCenterGameOrderQuery,
		params: PageOptions
	) => {
		if (!gameCenterAdminClient) return;
		try {
			console.log('getOrders', query, params);
			dispatch(setIsLoading(true));
			const res = await gameCenterAdminClient.getGameCenterGameOrders(
				query,
				params
			);
			setTotal(res.meta.totalRecord || 0);
			setOrders(res.data);
		} catch (error) {
			console.log('getSuppliers', error);
		} finally {
			dispatch(setIsLoading(false));
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		window.scrollTo(0, 0);
		setParams({
			...params,
			page: pagination.current as number,
			pageSize: pagination.pageSize as number,
		});
	};
	return (
		<Wrapper>
			<Filter setQuery={setQuery} params={params} setParams={setParams} />
			<CustomTable
				columns={columns}
				dataSource={orders}
				pageSize={params.pageSize}
				total={total}
				onChange={onChangeTable}
				current={params.page}
			/>
		</Wrapper>
	);
};

export default Orders;
