import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIdentityAdminClient } from 'user-identity-js';
import styled from 'styled-components';
import { ActionButtonWrapper, DisplayNameWrapper } from '../../UserList';
import Copy from '../../UI/Copy';
import { RootState, useAppDispatch } from '../../../redux/store';
import { shortenAddress, convertStatusToCapitalized } from '../../../utils';
import CustomTable from '../../UI/CustomTable';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import {
	GAME_EXPERIENCE_REGISTER_STATUS,
	QueryGameExRegisterDto,
} from 'skychain-game-center-js/lib/dto/game-experience.dto';
import { format } from 'date-fns';
import { GameShowRegister } from 'skychain-game-center-js/lib/dto/game-show.dto';
import { setIsLoading } from '../../../redux/reducers/system.slice';
import { PageOptions } from 'user-identity-js/lib/dto';
const Status = styled.div<{ status: GAME_EXPERIENCE_REGISTER_STATUS }>(
	(props) => `
     color: ${
				props.status === GAME_EXPERIENCE_REGISTER_STATUS.VALID
					? 'green'
					: props.status === GAME_EXPERIENCE_REGISTER_STATUS.INVALID
					? '#cf304a'
					: props.status === GAME_EXPERIENCE_REGISTER_STATUS.PENDING
					? '#FFA500'
					: '#737283'
			}
`
);
interface DataType {
	token: string;
	time: string;
	toAccount: string;
	amount: string;
	status: string;
	transaction_hash: string;
	fromAccount: string;
}

interface Props {
	query?: QueryGameExRegisterDto;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const GameShowRegisteredTable: React.FC<Props> = ({
	query,
	params,
	setParams,
}: Props) => {
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useAppDispatch();
	const [gameExs, setGameExs] = useState<GameShowRegister[]>([]);

	const [total, setTotal] = useState(0);
	const columns: ColumnsType<DataType> = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',

			render: (text) => (
				<DisplayNameWrapper>
					<p>{shortenAddress(text)}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'Time Shift',
			dataIndex: 'timeShift',
			key: 'timeShift',
		},
		{
			title: 'Register Time',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text) => (
				<p>{format(new Date(text * 1000), 'yyyy-MM-dd HH:mm')}</p>
			),
		},

		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (text) => (
				<Status status={text}>{convertStatusToCapitalized(text)}</Status>
			),
		},
	];

	useEffect(() => {
		getRegisterGameShow(gameCenterAdminClient, userIdentity, query, params);
	}, [gameCenterAdminClient, userIdentity, params, query]);

	const getRegisterGameShow = async (
		gameCenterAdminClient: GameCenterAdminClient | null,
		userIdentity: UserIdentityAdminClient | null,
		query?: QueryGameExRegisterDto,
		params?: any
	) => {
		if (!gameCenterAdminClient || !userIdentity) return;
		dispatch(setIsLoading(true));
		try {
			const res = await gameCenterAdminClient.getAllGameShowRegister(
				params,
				query
			);
			const users = await userIdentity.getUsers({
				ids: res.data.map((item) => item.userId).filter((id) => id) as string[],
			});
			setGameExs(
				res.data.map((item) => ({
					...item,
					email:
						users.data.find((user) => user.id === item.userId)?.email || '',
				}))
			);
			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('getTransactions', error);
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<>
			<CustomTable
				columns={columns}
				dataSource={gameExs}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
				current={params.page}
			/>
		</>
	);
};

export default GameShowRegisteredTable;
