import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { GameProfileAdminClient } from 'skychain-game-profile-js/lib/admin/game-profile-admin-client';
import { useEffect, useState } from 'react';
import {
	AccountTransactionEntity,
	RefTransactionEntity,
} from 'skychain-game-profile-js/lib/client/entities/all.entity';
import { PageOptions } from 'skychain-game-center-js/lib/dto';
import styled from 'styled-components';
import useModal from '../../../hooks/useModal';
import { Button, TablePaginationConfig, notification } from 'antd';
import {
	GameAccountTransactionStatus,
	REF_TRANSACTION_TYPES,
} from 'skychain-game-profile-js/lib/dto/ref.query';
import CustomTable from '../../UI/CustomTable';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import {
	convertStatusToCapitalized,
	formatCurrency,
	shortenAddress,
} from '../../../utils';
import { date_format } from '../../../config';
import RejectModal from '../../UI/RejectModal';
import { UserIdentityAdminClient } from 'user-identity-js';
import Copy from '../../UI/Copy';
export enum AuditAction {
	APPROVE = 'APPROVE',
	REJECT = 'REJECT',
}

const getTransactionStatus = (status: GameAccountTransactionStatus) => {
	switch (status) {
		case GameAccountTransactionStatus.PENDING:
			return '#FFBF1A';
		case GameAccountTransactionStatus.COMPLETED:
			return '#00FF00';
		case GameAccountTransactionStatus.FAILED:
			return '#FF0000';
		case GameAccountTransactionStatus.WAITING:
			return '#FFBF1A';
		case GameAccountTransactionStatus.APPROVED:
			return '#1C84E4';
		default:
			return '#ffffff';
	}
};

const getTransactionTypeColor = (type: REF_TRANSACTION_TYPES) => {
	switch (type) {
		case REF_TRANSACTION_TYPES.CASHBACK:
			return '#F7931E';
		case REF_TRANSACTION_TYPES.SAMELEVEL:
			return '#E2589A';
		case REF_TRANSACTION_TYPES.VOLUME_DOWNLINE:
			return '##29A961';
		default:
			return '#ffffff';
	}
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 16px;
`;
const Label = styled.div`
	color: #737283;
	font-size: 16px;
	line-height: 12px;
	width: 200px;
`;
const Value = styled.div`
	color: #fff;
	font-size: 16px;
	line-height: 22px;
`;

const Reason = styled.div`
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.08);
	color: #737283;
	font-size: 16px;
	line-height: 24px;
	padding: 16px 22px;
`;
const Status = styled.div<{ status: GameAccountTransactionStatus }>`
	font-size: 16px;
	line-height: 22px;
	color: ${(props) => `
		${getTransactionStatus(props.status)}
	`};
`;
const Row = styled.div`
	display: flex;
	align-items: center;
`;

const ReasonRow = styled(Row)`
	align-items: flex-start;
`;
const TxType = styled.div<{ type: string }>`
	font-size: 12px;
	line-height: 16px;
	color: ${(props) =>
		`${getTransactionTypeColor(props.type as REF_TRANSACTION_TYPES)}`};
`;
const Actions = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
`;

const ButtonAction = styled(Button)<{ action: AuditAction }>`
	display: flex;
	height: 50px;
	padding: 12px 140px;
	justify-content: center;
	align-items: center;
	background: ${(props) =>
		`${
			props.action === AuditAction.REJECT
				? '#484756'
				: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'
		}`};
	color: ${(props) =>
		`${props.action === AuditAction.REJECT ? '#FFFFFF' : '#181A20'}`};
	@media screen and (max-width: 576px) {
		padding: 12px 40px;
	}
`;
interface AuditCommisionDetail extends AccountTransactionEntity {
	userEmail: string;
}
const AuditCommisionDetail = () => {
	const params = useParams();
	const id = params.id;
	const [api, contextHolder] = notification.useNotification();
	const { isOpen, toggle } = useModal();
	const gameProfile = useSelector(
		(state: RootState) => state.system.gameProfile
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [txs, setTxs] = useState<RefTransactionEntity[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [detail, setDetail] = useState<AuditCommisionDetail>();
	const [paginationParams, setPaginationParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});

	const columns: ColumnsType<RefTransactionEntity> = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: (value) => <>{shortenAddress(value)}</>,
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: (value) => <>{format(new Date(value * 1000), date_format)}</>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			render: (value) => <>${formatCurrency(value)}</>,
		},
		{
			title: 'Commision Rate',
			render: (value, record: RefTransactionEntity) => (
				<>{record?.note?.commissionRate}%</>
			),
		},
		{
			title: 'Cause Amount',
			dataIndex: 'causeAmount',
			render: (value) => <>${formatCurrency(value)}</>,
		},
		{
			title: 'Type',
			dataIndex: 'type',
			render: (value) => (
				<TxType type={value}>{convertStatusToCapitalized(value)}</TxType>
			),
		},
	];
	useEffect(() => {
		getRefTransById(gameProfile, id as string, paginationParams);
	}, [gameProfile, id, paginationParams]);

	useEffect(() => {
		getPaymentTrans(gameProfile, userIdentity, id as string);
	}, [gameProfile, id]);

	const getPaymentTrans = async (
		gameProfile: GameProfileAdminClient,
		userIdentity: UserIdentityAdminClient | null,
		id: string
	) => {
		if (!gameProfile || !userIdentity || !id) return;
		try {
			const res = await gameProfile.getPaymentTrans(id);
			if (res?.userId) {
				const user = await userIdentity.getUserById(res.userId);
				setDetail({
					...res,
					userEmail: user?.data.email || '',
				});
			}
		} catch (error) {
			console.log('getPaymentTrans', error);
		}
	};

	const getRefTransById = async (
		gameProfile: GameProfileAdminClient,
		id: string,
		params: PageOptions
	) => {
		if (!gameProfile || !id) return;
		try {
			const res = await gameProfile.getRefTransByPaymentId(id, params);
			setTxs(res.data);
			setTotal(res.meta.totalRecord);
		} catch (error) {
			console.log('getRefTransById', error);
		}
	};

	const handleReject = async (reason: string) => {
		toggle();
		if (!gameProfile || !id) return;
		try {
			await gameProfile?.auditPayment(id, {
				status: GameAccountTransactionStatus.FAILED,
				failReason: reason,
			});
			getPaymentTrans(gameProfile, userIdentity, id as string);
			api.success({
				message: 'Reject successfully',
			});
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
			console.log(error);
		}
	};

	const approve = async () => {
		if (!gameProfile || !id) return;
		try {
			await gameProfile?.auditPayment(id, {
				status: GameAccountTransactionStatus.APPROVED,
			});
			getPaymentTrans(gameProfile, userIdentity, id as string);
			api.success({
				message: 'Approve successfully',
			});
		} catch (error: any) {
			api.error({
				message: error?.response?.data?.message || 'Something went wrong',
			});
			console.log(error);
		}
	};
	const onChangeTable = (pagination: TablePaginationConfig) => {
		setPaginationParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};
	return (
		<div>
			<Wrapper>
				<Row>
					<Label>User:</Label>
					<Value style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						{detail?.userEmail}{' '}
						{detail?.userEmail && <Copy text={detail?.userEmail} />}
					</Value>
				</Row>
				<Row>
					<Label>Total USDT Reward:</Label>
					<Value>{detail?.amount}</Value>
				</Row>
				<Row>
					<Label>Status:</Label>
					<Status status={detail?.status as GameAccountTransactionStatus}>
						{convertStatusToCapitalized(detail?.status || '')}
					</Status>
				</Row>
				{detail?.failedReason && (
					<ReasonRow>
						<Label>Reason:</Label>
						<Reason>{detail?.failedReason}</Reason>
					</ReasonRow>
				)}
				{(detail?.status === GameAccountTransactionStatus.PENDING ||
					detail?.status === GameAccountTransactionStatus.WAITING) && (
					<Actions>
						<ButtonAction action={AuditAction.REJECT} onClick={toggle}>
							Reject
						</ButtonAction>
						<ButtonAction action={AuditAction.APPROVE} onClick={approve}>
							Approve
						</ButtonAction>
					</Actions>
				)}
			</Wrapper>
			<CustomTable
				columns={columns}
				dataSource={txs}
				onChange={onChangeTable}
				pageSize={paginationParams.pageSize}
				total={total}
				current={paginationParams.page}
			/>
			<RejectModal
				isOpen={isOpen}
				toggle={toggle}
				handleSendReason={handleReject}
			/>
			{contextHolder}
		</div>
	);
};

export default AuditCommisionDetail;
