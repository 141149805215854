import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';

import { ActionButtonWrapper, DisplayNameWrapper } from '../UserList';
import { Button, notification } from 'antd';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { UserIdentityAdminClient } from 'user-identity-js';
import { RootState, useAppDispatch } from '../../redux/store';
import Copy from '../UI/Copy';
import {
	convertStatusToCapitalized,
	formatCurrency,
	shortenAddress,
} from '../../utils';
import { setIsLoading } from '../../redux/reducers/system.slice';
import {
	TransactionState as GameTransactionState,
	QueryTransaction,
	TransactionState,
	TransactionType,
} from 'skychain-game-center-js/lib/dto/transaction.dto';
import { GameCenterAdminClient } from 'skychain-game-center-js';
import RejectModal from '../UI/RejectModal';
import useModal from '../../hooks/useModal';
import { PageOptions } from 'skychain-game-center-js/lib/dto';

const Row = styled('div')`
	display: flex;
	gap: 10px;
`;

const Status = styled.div<{ status: GameTransactionState }>(
	(props) => `
     color: ${
				props.status === GameTransactionState.APPROVED ||
				props.status === TransactionState.COMPLETED
					? 'rgb(3, 166, 109)'
					: props.status === GameTransactionState.FAILED ||
					  props.status === GameTransactionState.REJECTED
					? '#cf304a'
					: props.status === GameTransactionState.PENDING ||
					  props.status === GameTransactionState.PROCESSING_POINT ||
					  props.status === GameTransactionState.WAITING_FOR_APPROVAL
					? '#FFA500'
					: '#737283'
			}
`
);

interface DataType {
	id: string;
	txnId: string;
	user: string;
	originalAmount: string;
	processAmount: string;
	balance: string;
	status: GameTransactionState;
	createdAt: number;
	userCode: string;
}

const key = 'withdraw-progress';
interface TransactionTableProps {
	query?: QueryTransaction;
	params: PageOptions;
	setParams: (params: PageOptions) => void;
}
const WithdrawProgressTable: React.FC<TransactionTableProps> = ({
	query,
	params,
	setParams,
}: TransactionTableProps) => {
	const [api, contextHolder] = notification.useNotification();
	const dispatch = useAppDispatch();
	const [selectedTransactionId, setSelectedTransactionId] = useState('');
	const gameCenterAdminClient = useSelector(
		(state: RootState) => state.system.gameCenterAdminClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const { isOpen, toggle } = useModal();
	const [transactions, setTransactions] = React.useState<DataType[]>([]);

	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (gameCenterAdminClient && userIdentity) {
			getTransactions(gameCenterAdminClient, userIdentity, query, params);
		}
	}, [gameCenterAdminClient, params, userIdentity, query]);

	const getTransactions = async (
		gameCenterAdminClient: GameCenterAdminClient,
		userIdentity: UserIdentityAdminClient,
		query?: QueryTransaction,
		params?: PageOptions
	) => {
		dispatch(setIsLoading(true));
		try {
			const res = await gameCenterAdminClient.getTransactions(query, params);
			const ids = await userIdentity.getUsers({
				ids: res.data.map((tx) => tx.userId).filter((id) => id) as string[],
			});
			setTransactions(
				res.data.map((tx) => ({
					...tx,
					user: ids.data.find((user) => user.id === tx.userId)?.email || '',
					status: tx.state || '',
				}))
			);
			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			console.log('getTransactions', error);
			dispatch(setIsLoading(false));
		}
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};

	const columns: ColumnsType<DataType> = [
		{
			title: 'Id',
			dataIndex: 'txnId',
			key: 'txnId',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{shortenAddress(text)}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'User code',
			dataIndex: 'userCode',
			key: 'userCode',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},
		{
			title: 'User',
			key: 'user',
			dataIndex: 'user',
			render: (text) => (
				<DisplayNameWrapper>
					<p>{text}</p>
					<ActionButtonWrapper>
						{!!text && <Copy text={text} />}
					</ActionButtonWrapper>
				</DisplayNameWrapper>
			),
		},

		{
			title: 'Request Amount',
			key: 'originalAmount',
			dataIndex: 'originalAmount',
			render: (text) => <>${formatCurrency(text, 4)}</>,
		},
		{
			title: 'Process Amount',
			key: 'processAmount',
			dataIndex: 'processAmount',
			render: (text) => <>${formatCurrency(text, 4)}</>,
		},
		{
			title: 'Balance',
			key: 'balance',
			dataIndex: 'balance',
			render: (text) => <>{formatCurrency(text, 4)}</>,
		},
		{
			title: 'Type',
			key: 'txnType',
			dataIndex: 'txnType',
			render: (text) => (
				<div
					style={{
						color:
							text === TransactionType.DEPOSIT
								? '#03a66d'
								: text == TransactionType.WITHDRAW ||
								  text === TransactionType.REFUND
								? '#cf304a'
								: '#ffa500',
					}}
				>
					{convertStatusToCapitalized(text)}
				</div>
			),
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (text) => (
				<Status status={text}>{convertStatusToCapitalized(text)}</Status>
			),
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
			render: (text) => (
				<>{format(new Date(text * 1000), 'MM/dd/yyyy hh:mm a')}</>
			),
		},
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render(_, record) {
				return (
					<Row>
						<Button
							disabled={record.status !== TransactionState.WAITING_FOR_APPROVAL}
							type="primary"
							ghost
							onClick={() => approveTx(record.id)}
							style={{
								color:
									record.status !== TransactionState.WAITING_FOR_APPROVAL
										? '#ffffff33'
										: '#FCD535',
								borderColor:
									record.status !== TransactionState.WAITING_FOR_APPROVAL
										? '#ffffff33'
										: '#FCD535',
							}}
						>
							Approve
						</Button>
						<Button
							disabled={record.status !== TransactionState.WAITING_FOR_APPROVAL}
							type="primary"
							ghost
							onClick={() => {
								setSelectedTransactionId(record.id);
								toggle();
							}}
							style={{
								color:
									record.status !== TransactionState.WAITING_FOR_APPROVAL
										? '#ffffff33'
										: '#FCD535',
								borderColor:
									record.status !== TransactionState.WAITING_FOR_APPROVAL
										? '#ffffff33'
										: '#FCD535',
							}}
						>
							Reject
						</Button>
					</Row>
				);
			},
		},
	];

	const approveTx = async (txId: string) => {
		if (!gameCenterAdminClient) return;
		try {
			const res = await gameCenterAdminClient.approveTransaction(txId);
			if (res) {
				api['success']({
					key,
					message: 'Approve transaction successfully',
				});
				if (!userIdentity) return;
				getTransactions(gameCenterAdminClient, userIdentity, query, params);
			}
		} catch (error: any) {
			console.log('approveTx', error);
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		}
	};

	const rejectTx = async (txId: string, reason: string) => {
		if (!gameCenterAdminClient) return;
		try {
			const res = await gameCenterAdminClient.rejectTransaction(txId, reason);
			if (res) {
				api['success']({
					key,
					message: 'Reject transaction successfully',
				});

				if (!userIdentity) return;
				getTransactions(gameCenterAdminClient, userIdentity, query, params);
			}
		} catch (error: any) {
			api['error']({
				key,
				message: error?.response?.data?.message || 'Unknown error',
			});
		} finally {
			toggle();
		}
	};

	return (
		<>
			{contextHolder}
			<CustomTable
				columns={columns}
				dataSource={transactions}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
				current={params.page}
			/>
			<RejectModal
				isOpen={isOpen}
				toggle={toggle}
				handleSendReason={(reason) => rejectTx(selectedTransactionId, reason)}
			/>
		</>
	);
};

export default WithdrawProgressTable;
