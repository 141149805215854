import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../UI/CustomTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { ActionWrapper } from '../KYC';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { ActionButtonWrapper } from '../KYC';
import DetailIcon from '../Icons/DetailIcon';
import TitlePage from '../UI/TitlePage';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import {
	ExecuteOrderStatus,
	ExecuteOrderType,
	QueryExecuteOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import { PageOptions } from 'user-identity-js/lib/dto';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { UserIdentityAdminClient } from 'user-identity-js';
import { format } from 'date-fns';
import Filter from './Filter';
import BigNumber from 'bignumber.js';
import { convertStatusToCapitalized } from '../../utils';
import { setIsLoading } from '../../redux/reducers/system.slice';
import { date_format } from '../../config';
export enum TYPE {
	BUY = 'buy',
	SELL = 'sell',
}

const Wrapper = styled('div')``;

const Header = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;

const TypeText = styled('p')<{ type?: string }>`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: ${(props) => `${props.type === TYPE.BUY ? '#03a66d' : '#CF304A'}`};
	text-transform: capitalize;
`;
const StatusText = styled('p')<{ status?: string }>`
	text-transform: capitalize;
	color: ${(props) =>
		`${
			props.status === ExecuteOrderStatus.CONFIRM_SUCCESS
				? '#03a66d'
				: props.status === ExecuteOrderStatus.CANCEL
				? '#CF304A'
				: '#ffffff'
		}`};
	${(props) =>
		props.status === ExecuteOrderStatus.PENDING &&
		`background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;`}
`;

const CryptoWrapper = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SymbolText = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	margin: 0px;
`;
const SymbolImage = styled('img')`
	width: 35px;
`;
const Mail = styled('p')`
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #1c84e4;
`;
const Title = styled('p')`
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	margin: 0px;
`;
interface Crypto {
	symbol: string;
	name: string;
}

interface DataType {
	id: string;
	userId: string;
	crypto: Crypto;
	email: string;
	fiatAmount: string | number;
	price: string | number;
	type: string;
	createdAt: string;
	status: string;
	targetUser: string;
	assetSymbol: string;
	fiatSymbol: string;
	// operation: string | number;
}

// const data: DataType[] = [
// 	{
// 		id: '123',
// 		userId: '1',
// 		crypto: { symbol: 'USDT', name: 'Tether USD' },
// 		email: 'gentnerqhva67@gmail.com',
// 		amount: 10000,
// 		limit: [50, 100],
// 		type: 'sell',
// 		createdAt: '05/23/2023 12:00 AM',
// 		status: 'Completed',
// 		operation: '564163132416354777',
// 	},
// ];

export default function BuySellCrypto() {
	const navigate = useNavigate();
	const p2pTrading = useSelector((state: RootState) => state.system.p2pTrading);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useAppDispatch();
	const [executes, setExecutes] = useState<DataType[]>([]);
	const [userQuery, setUserQuery] = useState<QueryExecuteOrder>();
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [total, setTotal] = useState(0);
	const columns: ColumnsType<DataType> = [
		{
			title: 'User ID',
			dataIndex: 'userId',
			key: 'userId',
		},
		{
			title: 'Cryptos',
			dataIndex: 'crypto',
			key: 'crypto',
			render: (crypto: Crypto) => (
				<CryptoWrapper>
					<SymbolImage
						src={`/images/tokens/${crypto.symbol.toLowerCase()}.svg`}
						alt={crypto.symbol}
					/>
					<div>
						<SymbolText>{crypto.symbol}</SymbolText>
						{/* <CryptoNameText>{crypto.name}</CryptoNameText> */}
					</div>
				</CryptoWrapper>
			),
		},
		{
			title: 'User',
			dataIndex: 'email',
			key: 'email',
			render: (text: string) => <Mail>{text}</Mail>,
		},
		{
			title: 'Fiat Amount',
			dataIndex: 'fiatAmount',
			key: 'fiatAmount',
			render: (fiatAmount: string | number, record) => (
				<p>
					{fiatAmount} {record.fiatSymbol}
				</p>
			),
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			render: (price: string | number, record) => (
				<p>
					{price}
					{record.fiatSymbol}
				</p>
			),
		},
		{
			title: 'Created Date',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Counterparty',
			key: 'targetUser',
			dataIndex: 'targetUser',
			render: (text: string) => <Mail>{text}</Mail>,
		},
		{
			title: 'Types',
			key: 'type',
			dataIndex: 'type',
			render: (text: string) => (
				<TypeText type={text.toLowerCase()}>{text}</TypeText>
			),
		},

		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text: string) => (
				<StatusText status={text}>
					{convertStatusToCapitalized(text).replace('Exorder', '')}
				</StatusText>
			),
		},
		// {
		// 	title: 'Operation',
		// 	dataIndex: 'operation',
		// 	key: 'operation',
		// 	render: (text: string | number) => <Copy text={text + ''} />,
		// },
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<ActionWrapper
					onClick={() => {
						navigate(`${ROUTES.BUY_SELL_CRYPTO}/${record['id']}`);
					}}
				>
					<p>Detail</p>
					<ActionButtonWrapper>
						<DetailIcon />
					</ActionButtonWrapper>
				</ActionWrapper>
			),
		},
	];

	const getExecutes = async (
		p2pTrading: P2PTradingAdminClient,
		userIdentity: UserIdentityAdminClient,
		queryExecuteOrder: QueryExecuteOrder | undefined,
		params: PageOptions
	) => {
		if (!p2pTrading || !userIdentity) return;
		dispatch(setIsLoading(true));
		try {
			const res = await p2pTrading.getAllExecuteOrders(
				queryExecuteOrder,
				params
			);
			const userIds = res.data.map((item) => item.user);
			const targetUserIds =
				res.data
					.map((item) => item.targetUser || '')
					.filter((targetUser) => targetUser && targetUser?.length > 0) || [];
			const users = await userIdentity?.getUsers({
				ids: userIds.concat(targetUserIds),
			});
			const adOrderIds = res.data.map((item) => item.adOrderId);
			const adOrders = await p2pTrading?.getAllAdOrders({
				ids: adOrderIds,
			});
			const data: any[] = res.data.map((item) => {
				const adOrderDetail = adOrders.data.find(
					(order) => order.id === item.adOrderId
				);
				const dataItem = {
					...item,
					id: item.id || '',
					userId: item.user,
					email: users?.data.find((user) => user.id === item.user)?.email || '',
					crypto: {
						symbol: adOrderDetail?.assetSymbol || '',
						name: adOrderDetail?.assetSymbol || '',
					},
					fiatAmount:
						item.amount && item.price
							? new BigNumber(item.amount).multipliedBy(item.price).toFixed(4)
							: '',
					price: item.price ? new BigNumber(item.price).toFixed(4) : '',
					targetUser:
						users?.data.find((user) => user.id === item.targetUser)?.email ||
						'',
					type: item.type === ExecuteOrderType['BUY'] ? 'buy' : 'sell',
					createdAt: item.createdAt
						? format(new Date(item.createdAt * 1000), date_format)
						: '',
					status: item.status || '',
					assetSymbol: adOrderDetail?.assetSymbol,
					fiatSymbol: adOrderDetail?.fiatSymbol,
				};
				return dataItem;
			});
			setExecutes(data);
			setTotal(res.meta.totalRecord);
			dispatch(setIsLoading(false));
		} catch (error) {
			console.log('getAds', error);
			dispatch(setIsLoading(false));
		}
	};

	const handleChangeParams = (query: QueryExecuteOrder) => {
		setParams({
			...params,
			page: 1,
		});
		setUserQuery(query);
	};

	const onChangeTable = (pagination: TablePaginationConfig) => {
		setParams({
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
		});
	};

	useEffect(() => {
		if (p2pTrading && userIdentity) {
			getExecutes(p2pTrading, userIdentity, userQuery, params);
		}
	}, [p2pTrading, userIdentity, params, userQuery]);

	return (
		<Wrapper>
			<TitlePage title={<Title>List Buy Sell Crypto</Title>} />
			<Header>
				<Filter handleFilter={handleChangeParams} />
				{/* <Button type="primary" ghost>
					Export Excel
				</Button> */}
			</Header>

			<CustomTable
				columns={columns}
				dataSource={executes}
				onChange={onChangeTable}
				pageSize={params.pageSize}
				total={total}
				current={params.page}
			/>
		</Wrapper>
	);
}
