import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AuditCommissionFilter from './Filter';
import AuditCommissionTable from './Table';
import { IRefAuditQuery } from 'skychain-game-profile-js/lib/dto/ref.query';
import { useSearchParams } from 'react-router-dom';
import { PageOptions } from 'skychain-game-profile-js/lib/dto/base.query';
import { END_OF_WEEK, START_OF_WEEK } from '../../../constants';

const Wrapper = styled('div')`
	margin-top: 10px;
`;

export default function AuditCommission() {
	const [query, setQuery] = useState<IRefAuditQuery>({
		fromTime: START_OF_WEEK,
		toTime: END_OF_WEEK,
	});
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
		// order: PageOrder.DESC,
	});
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const email = searchParams.get('email');
		const fromTime = searchParams.get('fromTime');
		const toTime = searchParams.get('toTime');
		const type = searchParams.get('type');
		const status = searchParams.get('status');
		const newParams: IRefAuditQuery = {
			fromTime: fromTime ? parseInt(fromTime) : START_OF_WEEK,
			toTime: toTime ? parseInt(toTime) : END_OF_WEEK,
		};
		if (email) newParams['email'] = email;
		if (type) newParams['type'] = type;
		if (status) newParams['status'] = status;
		setQuery(newParams);
	}, [searchParams]);

	return (
		<Wrapper>
			<AuditCommissionFilter
				setQuery={setQuery}
				query={query}
				params={params}
				setParams={setParams}
			/>
			<AuditCommissionTable
				query={query}
				params={params}
				setParams={setParams}
			/>
		</Wrapper>
	);
}
