import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { P2PTradingAdminClient } from 'skychain-p2p-trading-js';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities';
import { ExecuteOrderAdminEntity } from 'skychain-p2p-trading-js/lib/entities/execute-order.entity';
import { UserIdentityAdminClient } from 'user-identity-js';
import { UserEntity } from 'user-identity-js/lib/entities';
import { RootState, useAppDispatch } from '../../../redux/store';
import Body from './Body';
import Title from './Title';
import {
	AdOrderAssetSymbol,
	AdOrderFiatSymbol,
	AdOrderStatus,
} from 'skychain-p2p-trading-js/lib/dto';
import LayoutDetailPage from '../../../layouts/LayoutDetailPage';
import { setIsLoading } from '../../../redux/reducers/system.slice';

export interface ExecuteDetail extends ExecuteOrderAdminEntity {
	userInfo: UserEntity;
	paymentMethodInfo: PaymentMethodEntity | undefined;
	targetUserInfo: UserEntity | undefined;
	assetSymbol: AdOrderAssetSymbol | undefined;
	fiatSymbol: AdOrderFiatSymbol | undefined;
	adOrderStatus: AdOrderStatus | undefined;
}

export default function BuySellCryptoDetail() {
	const p2pTrading = useSelector((state: RootState) => state.system.p2pTrading);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [executeDetail, setExecuteDetail] = useState<ExecuteDetail>();
	const params = useParams();
	const dispatch = useAppDispatch();
	const id = params.id;

	useEffect(() => {
		if (p2pTrading && id && userIdentity) {
			getAdDetail(id, p2pTrading, userIdentity);
		}
	}, [p2pTrading, id, userIdentity]);

	const getAdDetail = async (
		id: string,
		p2pTrading: P2PTradingAdminClient,
		userIdentity: UserIdentityAdminClient
	) => {
		if (!p2pTrading) return;
		dispatch(setIsLoading(true));
		try {
			const res = await p2pTrading.getExecuteOrderDetail(id);
			const adOrderDetail = await p2pTrading.getAdOrderDetail(
				res.data.adOrderId
			);
			const userInfo = await userIdentity.getUser(res.data.user);

			const paymentMethod = await p2pTrading.getAllPaymentMethod({
				id: adOrderDetail.data.paymentMethod,
			});
			let targetUserInfo: UserEntity | undefined;
			if (res.data.targetUser) {
				targetUserInfo = await userIdentity.getUser(res.data.targetUser);
			}
			console.log('targetUserInfo', targetUserInfo);
			setExecuteDetail({
				...res.data,
				userInfo,
				paymentMethodInfo:
					paymentMethod.data.length > 0 ? paymentMethod.data[0] : undefined,
				targetUserInfo: targetUserInfo,
				assetSymbol: adOrderDetail.data.assetSymbol,
				fiatSymbol: adOrderDetail.data.fiatSymbol,
				adOrderStatus: adOrderDetail.data.status,
			});
			dispatch(setIsLoading(false));
		} catch (error) {
			dispatch(setIsLoading(false));
			console.log('getAdDetail', error);
		}
	};

	return (
		<LayoutDetailPage>
			{executeDetail && (
				<>
					<Title executeDetail={executeDetail} />
					<Body executeDetail={executeDetail} />
				</>
			)}
		</LayoutDetailPage>
	);
}
