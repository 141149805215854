import { Form, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CustomInput from './CustomInput';
import CloseIcon from '../Icons/CloseIcon';

const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		padding: 0px;
		border-radius: 10px;
		overflow: hidden;
	}
`;

const Content = styled('div')`
	position: relative;
	width: 100%;
	height: max-content;
`;
const CloseWrapper = styled('div')`
	cursor: pointer;
`;
const HeaderWrapper = styled('div')`
	display: flex;
	padding: 15px 20px;
	justify-content: space-between;
	align-items: center;
	background-color: #091229;
	p {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: 0.2px;
		color: #d1d4dc;
		margin: 0;
	}
`;
const BodyWrapper = styled('div')`
	background-color: #070c1e;
	padding: 20px;
`;
const InputWrapper = styled('div')`
	margin-bottom: 20px;
`;
const Label = styled('p')`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
`;
const ButtonWrapper = styled('button')`
	cursor: pointer;
	border: none;
	outline: none;
	width: 100%;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	border-radius: 4px;
	padding: 10px 20px;

	font-weight: 500;
	font-size: 18px;
	line-height: 21px;

	color: #181a20;
	margin-top: 20px;
	margin-bottom: 10px;
`;

interface PropsType {
	isOpen: boolean;
	toggle: () => void;
	handleSendReason: (reason: string) => void;
}

export default function RejectModal({
	isOpen,
	toggle,
	handleSendReason,
}: PropsType) {
	const [form] = Form.useForm();

	const handleFinish = (values: any) => {
		handleSendReason(values.reason);
	};

	return (
		<ModalWrapper
			title={null}
			closable={false}
			centered
			open={isOpen}
			onCancel={toggle}
			footer={null}
			width={'548px'}
		>
			<Content>
				<HeaderWrapper>
					<p>Reject Transaction</p>
					<CloseWrapper onClick={toggle}>
						<CloseIcon />
					</CloseWrapper>
				</HeaderWrapper>
				<BodyWrapper>
					<Form form={form} layout="vertical" onFinish={handleFinish}>
						<InputWrapper>
							<Label>Admin reason</Label>
							<CustomInput
								placeHolder="Enter your reason..."
								name="reason"
								rules={[
									{ required: true, message: 'Please input your reason!' },
								]}
							/>
						</InputWrapper>
						<ButtonWrapper type="submit">Reject</ButtonWrapper>
					</Form>
				</BodyWrapper>
			</Content>
		</ModalWrapper>
	);
}
